import React from "react";
import IconButton from "@mui/material/IconButton";
import SimpleBadge from "./SimpleBadge";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = {
  overrides: {
    MuiIconButton: {
      label: {
        height: "32px",
        width: "32px"
      }
    }
  }
};

export default function MessageCenter(props) {
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <IconButton
        style={{
          backgroundColor: "transparent",
          height: "60px",
          width: "56px"
        }}
        aria-label="SecuredMessageCenter"
        onClick={(e) => window.open("/securedmessagecenter/")}
      >
        <SimpleBadge UnreadMessageCount={props.UnreadMessageCount} />
      </IconButton>
    </ThemeProvider>
  );
}
