import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import Main from "./main";

export default function Index() {
  return <Main />;
}

ReactDOM.render(<Index />, document.getElementById("root"));
