import * as Constants from "../containers/collectBrokerInfo/statics/constants";

class APIUserSignAgreementForm {
  constructor(apiClass) {
    this.apiClass = apiClass;
  }

  getApi = async () => {
    const apiRoute =
      Constants.formsManagerRoot + "Documents/UserSignedAgreementForm";

    return await this.apiClass.CallApiSkipErrorHandling("get", apiRoute, {});
  };
}

export default APIUserSignAgreementForm;
