// Environment Variables
export const Domain = +process.env.REACT_APP_IS_PROD
  ? window.location.origin
  : "https://devnexus.admis.com";
const AccountSnapshot = process.env.REACT_APP_AZURE_API_ACCOUNTSNAPSHOT;
const UserPermissions = process.env.REACT_APP_AZURE_PERMISSION;

// Generic
export const apiStatementsRoot =
  process.env.REACT_APP_ENV === "DEV"
    ? `https://eqcnexapidev.admis.com/NexusStatement/api/`
    : Domain + "/api/statements/api/";
export const userSettingsRoot = Domain + "/api/usersettings/api/";
export const userPermissionsRoot = UserPermissions;
export const accountSearchRoot = Domain + "/api/accountsearch/api/";
// Statements
export const apiDailyStatements = "DailyStatements/dailystatementbyactivity";
export const apiDailyStatementsForAll =
  "DailyStatements/dailystatementforaccountall";
export const apiMonthlyStatementsForAll =
  "MonthlyStatements/monthlystatementforaccountall";

export const apiEquities = "EquityRuns/equityrunforallaccount";
export const apiEquitiesforAll = "EquityRuns/downloadequityrunforallaccount";
export const apiMonthlyStatements =
  "MonthlyStatements/monthlystatementforaccount";
export const apiYearlyStatements = "YearlyStatements/yearlystatementforaccount"; //referred as 1099s
export const apiDailyStatementsDates = "DailyStatements/activitydates";
export const apiEquitiesDates = "EquityRuns/dates";
export const apiMonthlyStatementsDates = "MonthlyStatements/dates";
export const apiYearlyStatementsDates = "YearlyStatements/dates"; // referred as 1099s
// Landing
export const graphApiRoot = process.env.REACT_APP_AZURE_API_GRAPH_B2C;
// Customer Center
export const apiCustomerCenterRoot = Domain + "/api/customercenter/api/";

// Account Snaphot
export const apiAccountSnapshotRoot = AccountSnapshot;
export const apiCashmanagerRoute = process.env.REACT_APP_AZURE_API_CASH_MANAGER;
// Quotes
export const apiQuotes = process.env.REACT_APP_AZURE_API_QUOTES;
export const apiStaticData = process.env.REACT_APP_AZURE_API_STATIC_DATA;
// PortfolioCalculator
export const apiPCRoot = process.env.REACT_APP_AZURE_API_PC;
export const apiProductSearchRoot = Domain + "/api/productsearch/api/";
// SecuredMessageRoot

export const messagesRoot =
  process.env.REACT_APP_AZURE_API_SECURED_MESSAGE_CENTER + "messages/";
export const secMSGRoot = Domain + "/api/messages/";
export const messagesEmailAutofill =
  Domain + "/api/messages/api/EmailAutofill/";

// ResearchAdmin
export const apiResearchRoot = Domain + "/api/research/api/";

//Research
export const apiSubscriptions =
  Domain + "/api/subscriptionsauth/api/EmailSender/";
export const apiArticles = Domain + "/api/research/api/Articles/";
