import * as Constants from "../containers/collectBrokerInfo/statics/constants";

class APIAvalancheUserVerify {
  constructor(apiClass) {
    this.apiClass = apiClass;
  }

  getApi = async () => {
    const apiRoute = Constants.grapthApiRoot + "Avalanche/VerifyAvalancheUser";

    return await this.apiClass.CallApiSkipErrorHandling("get", apiRoute);
  };
}

export default APIAvalancheUserVerify;
