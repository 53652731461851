import { b2cUserType } from "../auth-wrapper";
import { b2cGetAccount } from "../auth-wrapper";

export function NSupportEmail() {
  let userType = "";

  if (b2cGetAccount()?.username) {
    userType = b2cUserType();
  }

  switch (userType) {
    case "admis.com":
      return "supportdesk@admis.com";
    case "admisi.com":
      return "customer.services@admisi.com";
    default:
      return "supportdesk@admis.com";
  }
}
