import React from "react";
import "./Loading.css";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

const Loading = (props) => {
  return (
    <>
      <Modal
        open={true}
        aria-labelledby="LoadingBox"
        aria-describedby="LoadingBox"
      >
        <div id="LoadingBox">
          <div id="LoadingBoxItem">
            <CircularProgress size={120} />
          </div>
          <p>{props?.message ? props?.message : "Please wait, loading"}</p>
        </div>
      </Modal>
    </>
  );
};

export default Loading;
