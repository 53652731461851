import React, { useEffect, useState } from "react";
import { countryList } from "../../collectBrokerInfo/statics/countryList";
import {
  b2cGetAccount,
  b2cLogout
} from "../../../components/sharedComponents/auth-wrapper";
import { enqueueSnackbar } from "notistack";
import APIUserSignAgreementForm from "../../../services/APIUserSignAgreementForm";
import APIPostBrokerInfo from "../../../services/APIPostBrokerInfo";
import { NSupportEmail } from "../../../components/sharedComponents";
import PersonalInfoForm from "../personalnfoForm/PersonalInfoForm";
import AddAccountForm from "../addAccountForm/AddAccountForm";
import AgreementForm from "../agreementForm/AgreementForm";
import StepsForm from "../stepsForm/StepsForm";
import SubmitForm from "../submitForm/submitForm";

import "./multiStepForm.style.css";

const defaultData = {
  userCountry: "",
  userAddress: "",
  userAddress2: "",
  userCity: "",
  userState: "",
  userZip: "",
  userPhone: "",
  userAccounts: []
};

const formStepsAdmis = [
  {
    order: 1,
    name: "Personal Info"
  },
  {
    order: 2,
    name: "Account Info"
  },

  {
    order: 3,
    name: "Sign Agreement"
  },
  {
    order: 4,
    name: "Submit"
  }
];

const formStepsAdmisi = [
  {
    order: 1,
    name: "Personal Info"
  },
  {
    order: 2,
    name: "Account Info"
  },
  {
    order: 3,
    name: "Submit"
  }
];

const MultiStepForm = ({ apiClass }) => {
  const [formData, setFormData] = useState(defaultData);
  const [currentStep, setCurrentStep] = useState(1);
  const [isAgreementSigned, setIsAgreementSigned] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const apiUserSignAgreementForm = new APIUserSignAgreementForm(apiClass);
    const isUserSignedAgreementBefore = async () => {
      try {
        const response = await apiUserSignAgreementForm.getApi();
        setIsAgreementSigned(response);
      } catch (err) {
        console.log("error: ", err);
      }
    };
    isUserSignedAgreementBefore();
  }, [apiClass]);

  const userType = b2cGetAccount().idTokenClaims.extension_CustomerOfCountry;

  const onValueChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Send user info
  const sendUserInfo = async () => {
    const apiPostBrokerInfo = new APIPostBrokerInfo(apiClass);
    //Making all account info together
    let userAccountsString = "";
    formData.userAccounts.forEach((el) => {
      userAccountsString += `${el.value},`;
    });
    const request = {
      //-- this is from old app, still need to pass, otherwise api fails.
      brokerEmail: "",
      brokerFirstName: "",
      brokerLastName: "",
      brokerFirm: "",
      brokerPhoneNumber: "",
      oakLogin: "",
      noBrokerInfo: "",
      userOffice: "",
      userSalesCode: "",

      //PERSONAL INFO
      userCountry: formData.userCountry,
      userAddress: formData.userAddress + " " + formData.userAddress2,
      userCity: formData.userCity,
      userState: formData.userState,
      userZip: formData.userZip,
      userPhone: formData.userPhone,
      userEmail: b2cGetAccount().username,
      userAccount: userAccountsString
    };
    try {
      let response = await apiPostBrokerInfo.getApi(request);
      console.log("response", response);
    } catch (error) {
      enqueueSnackbar(
        `Something went wrong with submitting the form, please contact ${NSupportEmail()}`,
        { variant: "warning" }
      );
    }
  };

  const onAddAccounts = (newAccount) => {
    setFormData({
      ...formData,
      userAccounts: [...formData.userAccounts, newAccount]
    });
  };

  const onDeleteAccounts = (id) => {
    setFormData({
      ...formData,
      userAccounts: formData.userAccounts.filter((el) => el.id !== id)
    });
  };

  const onClickNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const onClickPrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else return;
  };

  const onClickSubmit = () => {
    sendUserInfo();
    setIsFormSubmitted(true);
  };

  // ----- ALL RENDER FUNCTIONS ----- //

  const renderForm = () => {
    return (
      <PersonalInfoForm
        formData={formData}
        onValueChangeHandler={onValueChangeHandler}
        // onSubmit={onPersonalInfoFormSubmit}
        countryList={countryList}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
      />
    );
  };

  const renderAccount = () => {
    return (
      <AddAccountForm
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        onAddAccounts={onAddAccounts}
        onDeleteAccounts={onDeleteAccounts}
        addedAccounts={formData.userAccounts}
      />
    );
  };

  const renderFormReview = () => {
    return (
      <SubmitForm
        onClickSubmit={onClickSubmit}
        onClickPrev={onClickPrev}
        formData={formData}
      />
    );
  };

  const renderAgreement = () => {
    return (
      <AgreementForm
        onClickSubmit={onClickSubmit}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        userType={userType}
        accountList={formData.userAccounts}
        // LAter
        // isAgreementSigned={isAgreementSigned}
        // For now
        isAgreementSigned={isAgreementSigned}
        setIsAgreementSigned={setIsAgreementSigned}
        apiClass={apiClass}
      />
    );
  };

  const renderAdmisView = () => {
    // console.log("current Step", currentStep);
    // console.log("user type", userType);
    switch (currentStep) {
      case 1:
        return renderForm();
      case 2:
        return renderAccount();
      case 3:
        return renderAgreement();
      case 4:
        return renderFormReview();
      default:
        break;
    }
  };

  const renderAdmisiView = () => {
    switch (currentStep) {
      case 1:
        return renderForm();
      case 2:
        return renderAccount();
      case 3:
        return renderFormReview();
      default:
        break;
    }
  };

  const renderEachView = () => {
    if (userType === "admis.com") {
      return renderAdmisView();
    } else return renderAdmisiView();
  };

  return (
    <div className="form__container">
      <h3 className="form__header">Collect Info</h3>
      <div className="form__steps-container">
        <StepsForm
          currentStep={currentStep}
          formSteps={
            userType === "admisi.com" ? formStepsAdmisi : formStepsAdmis
          }
          isFormSubmitted={isFormSubmitted}
        />
      </div>
      <div className="form__step-view">
        {!isFormSubmitted ? (
          renderEachView()
        ) : (
          <div className="success-box">
            <p className="success__message">
              Your form has been successfully submitted{" "}
            </p>
            <button
              className="success__btn--home"
              onClick={() => window.location.reload()}
            >
              Go to home page
            </button>
            <button
              className="success__btn--logout"
              onClick={() => b2cLogout()}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
