import React, { Component } from "react";
// MaterialUI
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import appDrawerIcon from "./img/appDrawerIcon.png";
import Button from "@mui/material/Button";

class MenuAppDrawer extends Component {
  state = {
    AppBox: false,
    anchorElappsUI: null,
    appsUI: false
  };

  popperDisplay = (event, popperName) => {
    const { currentTarget } = event;
    const anchorName = "anchorEl" + popperName;
    this.setState((state) => ({
      [anchorName]: currentTarget,
      [popperName]: !state.name
    }));
  };

  handleClickAway = (name) => {
    this.setState({
      [name]: false
    });
  };
  displayAllApps() {
    this.setState((prevState) => ({ AppBox: !prevState.AppBox }));
  }

  render() {
    const { anchorElappsUI, appsUI } = this.state;

    if (this.props.AppIcons) {
      return (
        <>
          <IconButton
            style={{ height: "60px", width: "56px" }}
            aria-label="Other Applications"
            onClick={(e) => this.popperDisplay(e, "appsUI")}
          >
            <img src={appDrawerIcon} alt="App Drawer" />
          </IconButton>
          <Popper
            id={
              this.state.AppBox === false ? "SimplePopper" : "SimplePopperOpen"
            }
            open={appsUI}
            placement="bottom-end"
            style={{
              width: "500px",
              zIndex: "9999",
              position: "absolute"
            }}
            anchorEl={anchorElappsUI}
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener
                onClickAway={(e) => this.handleClickAway("appsUI")}
              >
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={"appsUIBox"}>
                    <div
                      style={{
                        padding: "10px",
                        margin:
                          this.state.AppBox === false
                            ? "10px auto 0 auto"
                            : "0px auto 0 auto",
                        borderRadius: "4px",
                        boxShadow: "0 0 21px 0 rgba(219, 219, 219, 0.5)",
                        height: this.state.AppBox === false ? "260px" : "auto",
                        overflow: "hidden"
                      }}
                    >
                      {this.props.AppIcons()}
                    </div>
                    <hr style={{ margin: 0 }} />
                    {!this.state.AppBox && (
                      <Button
                        style={{
                          width: "100px",
                          boxShadow: "none",
                          margin: "0 auto",
                          display: "block"
                        }}
                        id="seemoreBtn"
                        size="small"
                        onClick={() => this.displayAllApps()}
                        disableRipple
                        disableFocusRipple
                        disableElevation
                      >
                        See More
                      </Button>
                    )}
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </>
      );
    } else return "";
  }
}

export default MenuAppDrawer;
