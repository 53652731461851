import React, { useState } from "react";
import "./addAccountForm.style.css";

const typeOptions = {
  officeAccount: "officeAccount",
  office: "office",
  accounName: "accountName"
};

const AddAccountFormAvalanche = ({
  onClickNext,
  onClickPrev,
  onVerifyAccount,
  verifiedAccounts,
  onDeleteAccounts
}) => {
  const [currentItem, setCurrentItem] = useState("");
  const [selectedType, setSelectedType] = useState(typeOptions.officeAccount);
  const [warningMessage, setWarningMessage] = useState("");

  const onChangeHandler = (e) => {
    setWarningMessage("");
    setCurrentItem(e.target.value);
  };

  const onAddClickHandler = () => {
    if (currentItem.length === 0) {
      return;
    }
    if (selectedType === "officeAccount" && currentItem.length !== 8) {
      return setWarningMessage("Please make sure length is 8 digits");
    }
    onVerifyAccount(currentItem);
    setCurrentItem("");
  };

  const onTypeChangeHandler = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="accounts-box">
      <div className="accounts-top">
        <p>To continue verification process, please provide information:</p>
        <div className="accounts__inputs">
          <div className="accounts__input-box select">
            <label>Choose type:</label>
            <select name="typeName" onChange={onTypeChangeHandler}>
              <option value={typeOptions.officeAccount}>
                Full Account Number (8 digits)
              </option>
            </select>
          </div>
          <div className="accounts__input-box input">
            <label>Provide Info: </label>
            <input
              name="accounts"
              value={currentItem}
              onChange={onChangeHandler}
            />
          </div>
          <button className="accounts__add-btn" onClick={onAddClickHandler}>
            +
          </button>
        </div>
        <span className="accounts__warning">{warningMessage}</span>
        <p>Verified account and office number:</p>
        <div className="accounts__tags-box">
          {verifiedAccounts.map((el, index) => (
            <div key={index} className="accounts__tag">
              <span className="accounts__tag--value ">
                {/* <span className='accounts__tag--type'>{el.type}:</span> */}
                {el}
              </span>
              <button onClick={() => onDeleteAccounts(el)}>&otimes;</button>
            </div>
          ))}
        </div>
      </div>
      <div className="accounts__btn-group">
        <button className="accounts__btn--prev" onClick={onClickPrev}>
          Prev
        </button>
        <button
          className={
            verifiedAccounts.length < 1
              ? "accounts__btn--next--disabled"
              : `accounts__btn--next`
          }
          onClick={onClickNext}
          disabled={verifiedAccounts.length < 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AddAccountFormAvalanche;
