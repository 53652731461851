import React, { Component } from "react";
import { LINKS } from "./components/app-list";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom"; // Router
import { AuthWrapper } from "./components/sharedComponents";
import App from "./containers/App";
import OnlineApp from "./containers/OnlineApp";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { Loading } from "./components/sharedComponents";
import "./index.css";
import configureStore from "./redux/configureStore";
import APITest from "./components/api-test";

const store = configureStore();

class Main extends Component {
  constructor(props) {
    super(props);
    // if user hits a link redirect them
    let AppRedirector = LINKS.filter(
      (app) => app.url.slice(0, -1) === window.location.pathname
    );
    if (AppRedirector.length > 0) {
      window.location.replace(
        "https://" + window.location.host + AppRedirector[0].url
      );
    }
  }

  render() {
    return (
      <>
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={true}
          isVerboseMode={false}
          loadingComponent={<Loading />}
          metaFileDirectory={packageInfo.homepage}
        >
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            autoHideDuration={3000}
          >
            <Provider store={store}>
              <BrowserRouter>
                <Switch>
                  {process.env.REACT_APP_AZURE_DOMAIN ===
                  "https://devnexus.admis.com/" ? (
                    <Route
                      path="/APITest"
                      component={(props) => (
                        <APITest {...props} {...this.props} />
                      )}
                    />
                  ) : (
                    ""
                  )}
                  <Route
                    exact
                    path="/onlineapp"
                    component={(props) => (
                      <OnlineApp {...props} {...this.props} />
                    )}
                  />
                  <Route
                    path="/"
                    component={(props) => <App {...props} {...this.props} />}
                  />
                </Switch>
              </BrowserRouter>
            </Provider>
          </SnackbarProvider>
        </CacheBuster>
      </>
    );
  }
}

export default AuthWrapper(Main);
