import React from "react";
import IMGYouDoNoTHaveAccess from "./do-not-have-access.png";
import "./index.css";
export default function index(props) {
  // Redirect the user to landing app
  setTimeout(function () {
    window.location.replace(window.location.origin);
  }, 5000);
  return (
    <div id="NoPermBox">
      <img
        id="NoPermLogo"
        src="https://cdnnexuscustomui.azureedge.net/login-ui/ADMIS-Logo.gif"
        alt="ADMIS Logo"
      />
      <div id="NoPermTextBox">
        <img
          id="NoPermNoAccess"
          src={IMGYouDoNoTHaveAccess}
          alt="You do not have access"
        />
        <div id="NoPermText">
          <p>
            You don't have a permission to use <br />
            {process.env.REACT_APP_NAME_FRIENDLY} Application
          </p>
          <button id="logoutButton" onClick={() => props?.logout()}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
