import * as Constants from "../containers/collectBrokerInfo/statics/constants";

class APIGetHelloSign {
  constructor(apiClass) {
    this.apiClass = apiClass;
  }

  getApi = async (params) => {
    const apiRoute = `${process.env.REACT_APP_FORMS_MANAGER}Singature/CreateEmbeddedSignatureLinkRequest`;
    return await this.apiClass.CallApi("post", apiRoute, params);
  };
}

export default APIGetHelloSign;
