import * as Constants from "../containers/collectBrokerInfo/statics/constants";

class APIAvalancheOfficeVerify {
  constructor(apiClass) {
    this.apiClass = apiClass;
  }

  getApi = async (officeAcct) => {
    const apiRoute = Constants.grapthApiRoot + "Avalanche/VerifyOfficAcct";

    let params = {
      officeAcct: officeAcct
    };

    return await this.apiClass.CallApiSkipErrorHandling(
      "get",
      apiRoute,
      params
    );
  };
}

export default APIAvalancheOfficeVerify;
