import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { connect } from "react-redux";
import Content from "./content";
import "./index.css";

// US is UserSettings
/**
 * @constructor
 * @param {Object} props The values sent in from the parent/calling file, usually consists of name, email, user settings,
 * Also includes the values that need to be able to be changed via slider and any and all tables that are stored locally
 * @returns The menu for user settings popper, has sliders, accounts, and the ability to sign out
 * @owner ADMIS
 */
function MenuUser(props) {
  /**@lends MenuUser */
  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);

  // props
  const userEmail = props.UserEmail;

  // functions
  // show/hide menu
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    setIsModalActive(false);
  };
  /**
   * @memberOf MenuUser
   * @owner ADMIS
   * @param {Object} switchSettings The object that holds the 3 used values in this function
   * @returns The switches for each value sent in from App.js, usually includes one of the three: Sidebar(Reports bar), Advanced, or Account name
   */
  const UsSwitches = (switchSettings) => {
    return (
      <ListItem>
        <Switch
          checked={switchSettings.checked}
          onClick={switchSettings.onClick}
        />
        <ListItemText primary={switchSettings.primary} />
      </ListItem>
    );
  };

  /**
   * Loops through the values sent in to see if it is needed to put them as a slider
   * Calls the UsSwitches function with the necessary information
   * @returns The call of the UsSwitches "function"
   * @owner ADMIS
   */
  function getUsSwitches() {
    return props.customSettings?.length > 0
      ? props.customSettings.map((eachVal) => {
          let type = eachVal[0];
          let usProp;
          let isChecked;

          if (eachVal[2]) {
            isChecked = props.getUserSettings[type][eachVal[1]][eachVal[2]];
            usProp = [eachVal[1], eachVal[2]];
          } else {
            isChecked = props.getUserSettings[type][eachVal[1]];
            usProp = [eachVal[1]];
          }

          let switchLabel = eachVal[1].replace(/([A-Z])/g, " $1").trim();
          if (eachVal[1] === "PageType") {
            switchLabel = "Advanced";
          }
          if (
            eachVal[1] === "Sidebar" &&
            (process.env.REACT_APP_ID === "nexuswebreportcreator" ||
              process.env.REACT_APP_ID === "nexuswebreports")
          ) {
            switchLabel = "Reports Bar";
          }

          return (
            <UsSwitches
              key={eachVal[1]}
              checked={isChecked}
              onClick={() => updateUserSettings(type, usProp, !isChecked)}
              primary={switchLabel}
            />
          );
        })
      : "";
  }

  /**
   *
   * @param {String} type Will always either be global or local depending on which settings are supposed to be updated
   * @param {Array} item The item that needs to be changed in the sliders if they are changed
   * @param {Boolean} settingsValue The value that the changed setting needs to be set to
   * @returns The updated list of settings
   * @owner ADMIS
   */
  async function updateUserSettings(type, item, settingsValue) {
    //global or not
    if (item[0] === "PageType") {
      settingsValue = settingsValue ? 1 : 0;
    }

    //Basically check if a value is global or local, then check if it contains sub objects that need to be changed
    let customData = {
      ...(type === "Global"
        ? props.getUserSettingsGlobal
        : props.getUserSettingsLocal),
      [item[0]]:
        typeof item[1] === "undefined"
          ? settingsValue
          : {
              ...props.getUserSettingsGlobal[item[0]],
              [item[1]]: settingsValue
            }
    };
    if (type === "Global") {
      await props.postUserSettingsGlobal(customData);
      return props.apiClass.PostUserSettings("global", customData);
    } else {
      await props.postUserSettingsLocal(customData);
      return props.apiClass.PostUserSettings(
        process.env.REACT_APP_ID,
        customData
      );
    }
  }

  const open = Boolean(anchorEl);
  let name = props.UserName ? props.UserName : props.UserEmail;
  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Avatar
            aria-describedby="MenuUser"
            type="button"
            onClick={handleClick}
            style={{ margin: "10px", marginRight: "10px" }}
          >
            {userEmail?.charAt(0).toUpperCase()}
          </Avatar>
          <Popper
            id="MenuUserPopper"
            open={open}
            placement="bottom-end"
            anchorEl={anchorEl}
            transition
            style={{ zIndex: isModalActive ? 99999999 : 99 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <List>
                    <ListItem>
                      <Typography
                        style={{
                          fontSize: "18px",
                          margin: 0,
                          letterSpacing: "normal",
                          marginTop: 0
                        }}
                      >
                        {name}
                        <br />
                        <Link
                          href="/customercenter/Profile"
                          style={{ color: "#000" }}
                        >
                          View Profile
                        </Link>
                      </Typography>
                    </ListItem>
                    {getUsSwitches()}
                  </List>
                  <Content {...props} apiClass={props.apiClass} />
                  <hr />
                  <List component="nav" style={{ textAlign: "right" }}>
                    <Button id="signoutBtn" onClick={props.b2cLogout}>
                      Sign out
                    </Button>
                  </List>
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getUserSettings: state.User.Settings,
    getUserSettingsLocal: state.User.Settings.Local,
    getUserSettingsGlobal: state.User.Settings.Global
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    postUserSettingsLocal: (Local) =>
      dispatch({ type: "USER_SETTINGS_LOCAL", Local }),
    postUserSettingsGlobal: (Global) =>
      dispatch({
        type: "USER_SETTINGS_GLOBAL",
        Global
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuUser);
