import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Badge from "@mui/material/Badge";
import NotificationIcon from "./notifications.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(2)
    },
    customBadge: {
      backgroundImage: "linear-gradient(to bottom, #c50808, #ff0000)",
      color: "white"
    }
  })
);

function SimpleBadge(props) {
  const classes = useStyles();
  let msgCount = 0;
  if (props.UnreadMessageCount && props.UnreadMessageCount > msgCount) {
    msgCount = props.UnreadMessageCount;
  }
  return (
    <div>
      <Badge
        classes={{ badge: classes.customBadge }}
        className={classes.margin}
        badgeContent={msgCount}
      >
        <img src={NotificationIcon} alt="Notifications" />
      </Badge>
    </div>
  );
}
export default SimpleBadge;
