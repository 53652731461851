import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

//These 3 next functions: not, intersection, and union were from https://material-ui.com/components/transfer-list/
//This file uses a lot of the code from the "Enhanced Transfer List" example from the above website
//Ask ADMIS if any questions that weren't answered by the mat ui website

/**
 *
 * @param {array} a The checked array
 * @param {array} b The new array of recently checked items
 * @returns A new array that filters out the items that don't match up in the lists
 */
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
/**
 *
 * @param {Array} a Also the checked array
 * @param {Array} b Also the new array of recently checked items
 * @returns An array that only contains the values both have
 */
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

/**
 *
 * @param {Array} a Again the checked array
 * @param {Array} b Again the new array of recently checked items
 * @returns A new array that adds the values that aren't already in checked items to the checked items
 */
function union(a, b) {
  return [...a, ...not(b, a)];
}

/**
 * @constructor
 * @param {Object} props Contains all the values sent in to this child component
 * @returns The checkboxes and all the data within the modal box that content.js presents
 */
function ListDesign(props) {
  // The array of checked boxes stored in this file
  const [checked, setChecked] = React.useState([]);
  const itemList = props.data;
  /**
   * Adds the checked values to this components checkedBoxes and also adds them to the parent components version
   * Mainly only used for clicking on the list item/text instead of the checkbox
   * @param {String} value The name of the box being checked
   */
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    //addCheckedBoxes is used here to correctly update list of checked boxes
    //whenever someone presses on the list item instead of the checkbox
    props.addCheckedBoxes(value);
    setChecked(newChecked);
  };

  /**
   *
   * @param {array} itemList The list of items that are being checked
   * @returns An array telling the amount of checkedBoxes
   */
  const numberOfChecked = (itemList) => intersection(checked, itemList).length;

  /**
   * Adds all list items being checked to the array of checked boxes in both parent and child component
   * @param {array} itemList The list of items being shown
   */
  const handleToggleAll = (itemList) => () => {
    if (numberOfChecked(itemList) === itemList.length) {
      setChecked(not(checked, itemList));
    } else {
      setChecked(union(checked, itemList));
    }
    props.addAllCheckedBoxes(itemList);
  };

  /**
   * This function/constant is the main returned value from this file, it creates all the necessary checkboxes
   * These checkboxes are then Sent back and displayed on the modal box
   * @param {String} title The header value saying what the checkboxes are in relation to
   * @param {Array} itemList The array of checked items
   * @returns The List of values sent in as checkboxes that are used to reset the settings
   */
  const customList = (title, itemList) => (
    <>
      <Card style={{ border: "1px solid #b1b1b1" }}>
        <CardHeader
          className="MenuUserHeader"
          style={{
            backgroundColor: "#f3f3f3",
            paddingLeft: "0px",
            paddingTop: "5px",
            paddingBottom: "5px"
          }}
          avatar={
            <Checkbox
              // style={{ paddingLeft: "0px" }}
              onClick={handleToggleAll(itemList)}
              checked={
                numberOfChecked(itemList) === itemList.length &&
                itemList.length !== 0
              }
              disableTouchRipple
              // disableRipple
              // disableFocusRipple
              indeterminate={
                numberOfChecked(itemList) !== itemList.length &&
                numberOfChecked(itemList) !== 0
              }
              disabled={itemList.length === 0}
              // inputProps={{
              //   "aria-label": "all itemList selected",
              // }}
              color="primary"
            />
          }
          //This right here does styling in a super weird way
          //Basically doesn't do what is in index.css unless these are here
          //Also changes the subheader to be a little smaller
          titleTypographyProps={{ variant: "inherit" }}
          title={title}
          subheaderTypographyProps={{ variant: "caption" }}
          subheader={`${numberOfChecked(itemList)}/${itemList.length} selected`}
        />
        <Divider />
        <List dense component="div" role="list" style={{ padding: "0px" }}>
          {itemList.map((value) => {
            let label;
            let eachVal;
            if (props.type === "Table Settings") {
              label = value[1];
              eachVal = value[0];
            } else if (value === "PageType") {
              eachVal = value;
              label = "Advanced";
            } else if (value === "Symbol") {
              eachVal = value;
              label = "Product Selection";
            } else if (value === "LayoutSettings") {
              eachVal = value;
              label = "Table Settings";
            } else {
              label = value.replaceAll(/([A-Z])/g, " $1");
              eachVal = value;
            }

            return (
              <ListItem
                style={{
                  padding: "0px"
                }}
                dense
                key={value}
                role="listitem"
                button
                disableTouchRipple
                disableRipple
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    id={eachVal}
                    color="primary"
                    checked={checked.indexOf(value) !== -1}
                    inputProps={{ "aria-labelledby": value }}
                    onClick={() => props.addCheckedBoxes(eachVal)}
                  />
                </ListItemIcon>
                <ListItemText
                  id={eachVal + "Text"}
                  style={{ paddingBottom: "0px" }}
                  primary={label}
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
      <br />
    </>
  );

  return <>{customList(props.type, itemList)}</>;
}
export default ListDesign;
