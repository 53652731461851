import React, { Component } from "react";
import {
  AppIcons,
  AppWrapper,
  EventHubRecorder,
  Footer,
  MenuUser,
  MessageCenter,
  ThemeCreate,
  Topbar
} from "../components/sharedComponents";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  postUserSettingsGlobal,
  postUserSettingsLocal,
  postUserSites
} from "./../redux/actions";
import {
  b2cUserType,
  b2cLogout,
  b2cGetAccount
} from "../components/sharedComponents/auth-wrapper";
// import CollectBrokerInfo from "./collectBrokerInfo/CollectBrokerInfo";
import MultiStepForm from "./collectInfo/multiStepForm/MultiStepForm";
import MultiStepFormAvalanche from "./collectInfo/multiStepForm/MultiStepFormAvalanche";
import APIAvalancheUserVerify from "../services/APIAvalancheUserVerify";
import Content from "./Content";

class App extends Component {
  constructor(props) {
    super(props);
    this.apiClass = props.ApiClass;
    this.state = {
      AppBox: false,
      isDemoUser: false,
      UserSettings: false,
      initialLoad: true,
      //remove with avalanche
      isAvalancheUser: "",
      firstName: "",
      lastName: ""
    };
    //remove with avalanche
    this.apiIsAvalancheUser = new APIAvalancheUserVerify(this.apiClass);
  }

  async setUserSettings() {
    // Get User's Settings
    let UserSettings = this.props.UserSettings;
    if (UserSettings) {
      if (UserSettings.local)
        this.props.postUserSettingsLocal(UserSettings.local.customData);
      if (UserSettings.global)
        this.props.postUserSettingsGlobal(UserSettings.global.customData);
    }
  }

  //remove with avalanche
  async isAvalancheCheck() {
    this.setState({ isAvalancheUser: false });
    try {
      const response = await this.apiIsAvalancheUser.getApi();
      if (response) {
        this.setState({
          isAvalancheUser: response.isAvalanche,
          firstName: response.firstName,
          lastName: response.lastName
        });
      }
    } catch (err) {
      if (err.response.data === "Failed Verification.") {
        this.setState({ isAvalancheUser: false });
        return null;
      } else {
        console.log("avalanche verify error:", err);
      }
    }
  }

  async componentDidMount() {
    this.props.postUserSites(this.props.UserPermissions);
    // Set UserSettings
    this.setUserSettings();
    if (typeof this.props.email !== "undefined") {
      // Make sure this occurs first load for sure, and from that point on, occurs only if page changes
      this.setState({ initialLoad: false }, () => {
        let PageName = "Homepage";
        EventHubRecorder(
          process.env.REACT_APP_CONNECTION_STRING,
          process.env.REACT_APP_NAME_FRIENDLY,
          this.props.email,
          PageName,
          ""
        );
      });
    }
  }

  componentDidUpdate() {
    let getMoreAppsObj = this.props.Icons.filter(
      (e) => e.appName === "nexuswebcollectbrokerinfo"
    );
    if (getMoreAppsObj.length > 0 && this.state.isAvalancheUser === "") {
      this.isAvalancheCheck();
    }
  }

  render() {
    let icons = this.props.Icons;
    let userType = b2cUserType();
    let userName = b2cGetAccount().name;
    let userEmail = b2cGetAccount().username;
    let getMoreAppsObj = icons.filter(
      (e) => e.appName === "nexuswebcollectbrokerinfo"
    );

    if (getMoreAppsObj.length > 0) {
      return (
        <div
          style={{
            display: "flex",
            backgroundColor: "#32407B",
            minHeight: "100svh",
            paddingBottom: 10
          }}
        >
          {/* <CollectBrokerInfo
            userType={userType}
            apiClass={this.apiClass}
            enqueueSnackbar={this.props.enqueueSnackbar}
          /> */}
          {this.state.isAvalancheUser ? (
            <MultiStepFormAvalanche
              apiClass={this.apiClass}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
            />
          ) : (
            <MultiStepForm apiClass={this.apiClass} />
          )}
        </div>
      );
    } else
      return (
        <>
          <div style={{ transition: "all 200ms ease-in-out" }}>
            <ThemeProvider theme={ThemeCreate("light")}>
              <CssBaseline />
              <Topbar
                userType={userType}
                sizeAppName={this.state.sizeAppName}
                sites={icons}
                AppName={process.env.REACT_APP_NAME}
                name={userName}
                MenuUserSettings={
                  <MenuUser
                    UserSettings={this.props.getUserSettings}
                    apiClass={this.apiClass}
                    UserName={userName}
                    UserEmail={userEmail}
                    b2cLogout={b2cLogout}
                  />
                }
                MessageCenter={
                  !this.props.IsDemoUser && (
                    <MessageCenter
                      UnreadMessageCount={this.props.UnreadMessageCount}
                    />
                  )
                }
                AppIcons={() => {
                  return (
                    <AppIcons
                      iconSizes={[12, 6, 6, 4]} // xs, sm, md, lg
                      sites={icons}
                      UnreadMessageCount={this.props.UnreadMessageCount}
                      pendingUsersCount={this.props.PendingUsersCount}
                      fontSize="0.9rem"
                      iconImageSize="75px"
                    />
                  );
                }}
              />
              <div id="Content">
                <Content
                  apiClass={this.apiClass}
                  userEmail={userEmail}
                  UnreadMessageCount={this.props.UnreadMessageCount}
                  sites={icons}
                  PendingUsersCount={this.props.PendingUsersCount}
                  isDemoUser={this.props.IsDemoUser}
                  icons={icons}
                  userType={userType}
                />
              </div>
              <Footer userType={userType} />
            </ThemeProvider>
          </div>
        </>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    getUserSettings: state.User.Settings,
    getUserSettingsGlobal: state.User.Settings.Global,
    getUserSites: state.User.Sites
  };
};

const mapDispatchToProps = {
  postUserSettingsLocal,
  postUserSettingsGlobal,
  postUserSites
};
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper(App));
