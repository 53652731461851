import * as Constants from "../containers/collectBrokerInfo/statics/constants";

class APIPostBrokerInfo {
  constructor(apiClass) {
    this.apiClass = apiClass;
  }

  getApi = async (request) => {
    const apiRoute = Constants.grapthApiRoot + "BrokerInfo/PostBrokerInfo";
    let params = {
      brokerEmail: request.brokerEmail,
      brokerFirstName: request.brokerFirstName,
      brokerLastName: request.brokerLastName,
      brokerFirm: request.brokerFirm,
      brokerPhoneNumber: request.brokerPhoneNumber,
      userEmail: request.userEmail,
      // firstName: request.FirstName,
      // lastName: request.LastName,
      //get those fields from b2c and write to db

      userAddress: request.userAddress,
      userCity: request.userCity,
      userState: request.userState,
      userZip: request.userZip,
      userPhone: request.userPhone,
      userAccount: request.userAccount,
      userSalesCode: request.userSalesCode,
      userOffice: request.userOffice,
      userCountry: request.userCountry,
      noBrokerInfo: request.noBrokerInfo,
      oakLogin: request.oakLogin
    };
    return await this.apiClass.CallApiSkipErrorHandling(
      "post",
      apiRoute,
      params
    );
  };
}

export default APIPostBrokerInfo;
