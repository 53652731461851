import React, { Component } from "react";
import ApiClass from "../api-class";
import AppNoPermission from "./app-no-permission";
import IdleCheck from "./idle-check";
import {
  basicPermissions,
  userStatusDoesNotExistInDb,
  userStatusWaitingForBrokerApproval
} from "./constants";
import "bootstrap/dist/css/bootstrap.css";
import {
  b2cLogoutSoft,
  b2cGetSessionTimeLeft,
  b2cLogout,
  fetchToken
} from "../auth-wrapper";

// eslint-disable-next-line import/no-anonymous-default-export
export default (C) =>
  class AppWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ready: false,
        CurrentAppPermission: null,
        PendingUsersCount: 0,
        UserPermissions: null,
        UserPermissionDetailed: null,
        UserSettings: null,
        Icons: [],
        IsDemoUser: false,
        UnreadMessageCount: 0
      };
      this.apiClass = new ApiClass(props);
    }

    async componentDidMount() {
      await Promise.all([
        this.apiClass.GetUserPermissionsCombined(),
        this.apiClass.GetUserSettings()
      ]).then(async (data) => {
        const UserPermissionsCombined = data[0];
        const UserSettings = data[1];
        const UserPermissions = await this.HandleUserPermissions(
          UserPermissionsCombined.allowedApps
        );
        const UserPermissionDetailed = await this.HandleUserPermissionsDetailed(
          UserPermissionsCombined.permissions
        );

        // Is Current App Landing? IF so no need to check current app permission
        let CurrentAppPermission =
          process.env.REACT_APP_ID === "nexusweblanding"
            ? true
            : UserPermissions.some(
                (eachApp) => eachApp.appName === process.env.REACT_APP_ID
              );

        this.setState(
          {
            UserPermissions,
            UserPermissionDetailed,
            UserSettings,
            CurrentAppPermission,
            ready: true
          },
          async () => {
            this.handleIcons(UserPermissions, UserSettings);
            this.handleUnreadMessageCount();
            this.handlePendingUserCount(UserPermissions);
          }
        );
      });
    }

    handleIcons = async (UserPermissions, UserSettings) => {
      let Icons = await this.GetOrderedIcons(UserPermissions, UserSettings);
      this.setState({
        Icons
      });
    };

    handleUnreadMessageCount = async () => {
      let UnreadMessageCount = await this.apiClass.GetUnreadMessages();
      UnreadMessageCount =
        typeof UnreadMessageCount !== "undefined" ? UnreadMessageCount : 0;
      this.setState({
        UnreadMessageCount
      });
    };

    handlePendingUserCount = async (UserPermissions) => {
      let PendingUsersCount = await this.GetPendingUsersCount(UserPermissions);
      PendingUsersCount =
        typeof PendingUsersCount !== "undefined" ? PendingUsersCount : 0;
      this.setState({
        PendingUsersCount
      });
    };

    // This method takes GetAllowedApps apps array and depending on IconsLayout (from Global settings)
    // sets the order of apps that would reflect the order of icons on landing page and app drawer
    GetOrderedIcons = async (UserPermissions, UserSettings) => {
      //if UserPermissions are empty - user has no permissions - return empty
      if (!UserPermissions || UserPermissions.length === 0) {
        return [];
      }
      if (
        !UserSettings ||
        !UserSettings.global ||
        !UserSettings.global.customData ||
        !UserSettings.global.customData.LayoutSettings ||
        !UserSettings.global.customData.LayoutSettings.Landing ||
        !UserSettings.global.customData.LayoutSettings.Landing.IconsLayout
      ) {
        // for new users or users that reset their global settings
        // return what ever we have in Permissions
        return UserPermissions;
      }
      let orderedFromGlobalSettings =
        UserSettings.global.customData.LayoutSettings.Landing.IconsLayout;
      let resultArray = [];
      // we take all apps from GetAllowedApps (UserPermissions) go through them
      // checking if we have a corresponding app in IconsLayout if we do - put that app in correct order in resultArray, if not
      // it means that we an app that is in permissions, but previously was not present or saved on the layout
      let orderedApps = [];
      let newIcons = [];
      for (let i = 0; i < UserPermissions.length; i++) {
        let permissionApp = UserPermissions[i];
        let index = orderedFromGlobalSettings.findIndex(
          (c) => c === permissionApp.appId
        );
        if (index !== -1) {
          orderedApps[index] = permissionApp; // if we find a match - put that appid in corresponding cell in the result array
          // here we might end up with orderedApps array look like this [empty, 1, 6, 5],
          // that might happen if a app was removed from user permissions
          // we will filter out those empty cells later
        }
        // here we put apps that are in permissions but previously were not saved on the layout. we will add them at the end of resultArray
        else newIcons.push(permissionApp);
      }
      for (let a = 0; a < orderedApps.length; a++) {
        if (orderedApps[a]) resultArray.push(orderedApps[a]);
        // if undefined then skip
      }
      for (let n = 0; n < newIcons.length; n++) resultArray.push(newIcons[n]);
      return resultArray;
    };

    // User's Permissions
    UserPermissions = async () => {
      const res = await this.apiClass.GetUserPermissions();
      return await this.HandleUserPermissions(res);
    };

    HandleUserPermissions = (res) => {
      return res.apps.length < 3 ? this.NewUserSet(res.apps) : res.apps;
    };

    NewUserSet = async (res) => {
      let ListOfPermissions = [];
      let isUserInNexus = await this.apiClass.GetIsUserInNexus();
      if (isUserInNexus === "Active") {
        ListOfPermissions = res;
      }
      // new user. doesn't exist in database, we need to give him some basic apps
      else if (isUserInNexus === userStatusDoesNotExistInDb) {
        ListOfPermissions = [basicPermissions[0]];
        // this.setState({ IsDemoUser: true });
      }
      // User already send broker info. mark him somehow and do not show nexuswebcollectbrokerinfo
      else if (isUserInNexus === userStatusWaitingForBrokerApproval) {
        ListOfPermissions = [basicPermissions[1]];
        this.setState({ IsDemoUser: true });
      }
      return ListOfPermissions;
    };

    // Get User's Permissions
    GetPendingUsersCount = (UserPermissionList) => {
      // Does this user have access to BrokerAdmin?
      let BrokerAdminAccess = UserPermissionList.some(
        (eachApp) => eachApp.appName === "nexuswebbrokeradmin"
      );

      // Get Pending UserCount for Broker
      return BrokerAdminAccess ? this.apiClass.GetPendingUsersCount() : 0;
    };

    // Get User's Permissions for this App
    GetUserPermissionsDetailed = () => {
      return this.apiClass.GetUserPermissionsDetailed().then((res) => {
        return this.HandleUserPermissionsDetailed(res);
      });
    };

    HandleUserPermissionsDetailed = (res) => {
      return res.permission.map((c) => c.permissionName);
    };

    render() {
      const {
        ready,
        CurrentAppPermission,
        UserPermissions,
        UserPermissionDetailed,
        UserSettings,
        UnreadMessageCount,
        PendingUsersCount,
        IsDemoUser,
        Icons
      } = this.state;

      // All API Calls Done
      if (ready)
        if (CurrentAppPermission)
          return (
            <>
              {+process.env.REACT_APP_IS_PROD ? (
                <IdleCheck
                  acquireTokenPossibly={fetchToken}
                  logout={b2cLogout}
                  logoutSoft={b2cLogoutSoft}
                  b2cGetSessionTimeLeft={b2cGetSessionTimeLeft}
                />
              ) : (
                ""
              )}
              <C
                {...this.props}
                ApiClass={this.apiClass}
                PendingUsersCount={PendingUsersCount}
                IsDemoUser={IsDemoUser}
                UserPermissions={UserPermissions}
                UserPermissionDetailed={UserPermissionDetailed}
                UserSettings={UserSettings}
                UnreadMessageCount={UnreadMessageCount}
                Icons={Icons}
              />
            </>
          );
        else return <AppNoPermission logout={b2cLogout} />;
      else return "";
    }
  };
