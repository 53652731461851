// Return all nexus apps for the user
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function AppIcons(props) {
  function Site(props, unReadMSG, iconImageSize, fontSize, pendingUsersCount) {
    let text = 0;
    if (props.appName === "nexuswebsecuredmessagecenter" && unReadMSG > 0) {
      text = unReadMSG;
    }
    if (
      (props.appName === "nexuswebbrokeradmin" ||
        props.appName === "nexuswebadmin") &&
      pendingUsersCount > 0
    ) {
      text = pendingUsersCount;
    }
    return (
      <Card
        elevation={0}
        style={{ background: "transparent", position: "relative" }}
      >
        {text > 0 ? (
          <div
            style={{
              position: "absolute",
              backgroundImage: "linear-gradient(to bottom, #c50808, #ff0000)",
              color: "white",
              right: "30%",
              top: "5%",
              borderRadius: "10px",
              zIndex: "9",
              minWidth: "20px",
              height: "20px",
              padding: "0 6px",
              display: "flex",
              flexWrap: "wrap",
              fontSize: "0.75rem",
              boxSizing: "border-box",
              transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              alignItems: "center",
              fontWeight: 500,
              lineHeight: 1,
              alignContent: "center",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            {text}
          </div>
        ) : (
          ""
        )}
        <Link
          href={props.appUrl}
          style={{ textDecoration: "none" }}
          target={props.appName === "oakweb" ? "_blank" : "_self"}
          rel={props.appName === "oakweb" ? "noreferrer" : ""}
        >
          <img
            style={{ display: "block", margin: "0 auto 10px auto" }}
            src={props.icon}
            alt={props.title}
            width={iconImageSize}
          />
          <Typography
            noWrap
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: fontSize,
              fontFamily: "'Roboto', sans-serif"
            }}
          >
            {props.title}
          </Typography>
        </Link>
      </Card>
    );
  }

  return (
    <Grid container>
      {props.sites
        ? props.sites
            .filter((i) => i.icon && i.appUrl)
            .map((item, index) => (
              <Grid
                item
                style={{ margin: "10px 0" }}
                key={index}
                xs={props.iconSizes[0]}
                sm={props.iconSizes[1]}
                md={props.iconSizes[2]}
                lg={props.iconSizes[3]}
              >
                {Site(
                  item,
                  props.UnreadMessageCount,
                  props.iconImageSize,
                  props.fontSize,
                  props.pendingUsersCount
                )}
              </Grid>
            ))
        : ""}
    </Grid>
  );
}
