import ModalBox from "../modal-box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconRestore from "@mui/icons-material/Restore";
import { isEqual as _isEqual } from "lodash";
import React, { useState } from "react";
import { DefaultSettings } from "./constants";
import ListDesign from "./listDesign";

// US is UserSettings
/**
 * The whole function that encases this component
 * Runs everything
 * @constructor
 * @param {Object} props Takes in all the values sent to it during the call
 * @returns The modal box, the button that opens the modal, as well as shows all the data in the modal box
 * @owner ADMIS
 */
function Content(props) {
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  let localUsPerApp = DefaultSettings["Local"][process.env.REACT_APP_ID];
  const [modal, setModal] = useState(false);
  const apiClass = props.apiClass;

  function openModal() {
    setModal(true);
  }

  function closeModal() {
    setCheckedBoxes([]);
    setModal(false);
  }

  /**
   * Checks to see by ID name what boxes have and have not been checked out of what can be seen on the list
   * Runs through all global local and table boxes to see what has changed
   * Resets the checked settings to their "Defaults" Shown as an object in constants.js
   * @returns the function necessary to reload the page
   * @memberof Content
   * @owner ADMIS
   * @name Content#resetSettings
   */
  async function resetSettings() {
    //Gets a new variable separated from UserSettings global values
    //This allows the program to see all the newest changes made by the user
    let newGlobalSettings = JSON.parse(
      JSON.stringify(props.getUserSettings["Global"])
    );
    //Same as for newGlobalSettings but with local
    let newLocalSettings = JSON.parse(
      JSON.stringify(props.getUserSettings["Local"])
    );

    //Able to use a for of loop here because globalChanges will always at least exist
    //It will never be undefined like table or local changes, only []
    for (const el of globalChanges) {
      var checkedValGlobal = document.getElementById(el);
      if (checkedValGlobal.checked) {
        //Only changes the checked values back to the default settings
        //It sets the new settings, with all the changes, back to the values from the defaults
        //This one does it on all the boxes checked in global settings section
        newGlobalSettings[el] = DefaultSettings["Global"][el];
      }
    }
    for (let i = 0; i < localChanges?.length; i++) {
      var checkedValLocal = document.getElementById(localChanges[i]);
      if (checkedValLocal?.checked) {
        //Same as the global version but for the local settings.
        newLocalSettings[localChanges[i]] = localUsPerApp[localChanges[i]];
      }
    }

    for (let i = 0; i < props.tableReset?.length; i++) {
      var checkedValTable = document.getElementById(props.tableReset[i][0]);
      if (checkedValTable.checked) {
        localStorage.removeItem(props.tableReset[i][0]);
      }
    }
    //Both sets of updated settings get posted then the page reloads
    setCheckedBoxes([]);
    await apiClass.PostUserSettings("global", newGlobalSettings);
    await apiClass.PostUserSettings(process.env.REACT_APP_ID, newLocalSettings);
    return window.location.reload();
  }

  /**
   * Basically filters out all items in the array that haven't been changed
   * Also filters out if the user contains a setting that is not in the default settings at all to prevent bugs
   * @param {String} type Contains whether the changes being searched for are global or local changes
   * @returns Returns an array of only the settings that have been changed, others are filtered out
   * @owner ADMIS
   * @name Content#getUSChanges
   */
  function getUSChanges(type) {
    let usKeys = Object.keys(props.getUserSettings[type]); // give me only userSettingsKeys of items that user did make change
    let defaultKeys;
    let filteredUSKeys;
    if (type === "Global") {
      defaultKeys = JSON.parse(
        JSON.stringify(Object.keys(DefaultSettings["Global"]))
      );
      filteredUSKeys = usKeys.filter(
        (eachUS) =>
          //filters out all values that have not changed
          !_isEqual(
            DefaultSettings[type][eachUS],
            props.getUserSettings[type][eachUS]
          )
      );
    } else if (DefaultSettings["Local"][process.env.REACT_APP_ID]) {
      defaultKeys = JSON.parse(
        JSON.stringify(
          Object.keys(DefaultSettings["Local"][process.env.REACT_APP_ID])
        )
      );

      filteredUSKeys = usKeys.filter(
        (eachUS) =>
          //filters out all values that have not changed
          !_isEqual(localUsPerApp[eachUS], props.getUserSettings[type][eachUS])
      );
    }
    //Checks to see if the value is also included in DefaultSettings(constants.js) if not, don't display it
    filteredUSKeys = filteredUSKeys?.filter(function (el) {
      return defaultKeys.indexOf(el) > -1;
    });

    return filteredUSKeys;
  }

  /**
   * Checks if there are any values being shown, if not tells the user no settings have changed
   * If there are settings that have changed, gives them both buttons necessary to reset or close the modal box
   * @returns The buttons necessary for resetting settings and closing the modal box
   * @owner ADMIS
   */
  function getFormButtons() {
    let globalList = getUSChanges("Global");
    let localList = getUSChanges("Local");
    if (
      (globalList.length === 0) &
      (localList?.length === 0 || localList === undefined) &
      !props.tableReset
    ) {
      return (
        <>
          <h4 style={{ userSelect: "none" }}>
            You have not changed any settings
          </h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" id="closeButton" onClick={closeModal}>
              Return to Page
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="ResetButton"
            disabled={checkedBoxes.length < 1}
            variant="contained"
            onClick={resetSettings}
            color="primary"
          >
            Reset Settings
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" id="closeButton" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      );
    }
  }

  /**
   * If a checkbox is checked it gets added to checked boxes
   * If a checkbox becomes unchecked, remove it from the array
   * Needed because the reset settings button will only be enabled if there is a value in checkedBoxes
   * @param {String} newBox The value that will be added to the checkedBoxes array
   * @owner ADMIS
   */
  function addCheckedBoxes(newBox) {
    const currentIndex = checkedBoxes.indexOf(newBox);
    const newChecked = [...checkedBoxes];

    if (currentIndex === -1) {
      newChecked.push(newBox);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedBoxes(newChecked);
  }

  /**
   * Calls the function to add the checked boxes for as many are in the sublist
   * @param {Array} boxGroup Contains the list of checked boxes should the check all boxes be checked
   * @owner ADMIS
   */
  function addAllCheckedBoxes(boxGroup) {
    for (const element of boxGroup) {
      addCheckedBoxes(element);
    }
  }

  const globalChanges = getUSChanges("Global");
  const localChanges = getUSChanges("Local");

  return (
    <div id="MenuUser">
      <List id="MenuUserList">
        <ListItem>
          <IconButton
            style={{ color: "#333" }}
            data-testid="iconbutton"
            onClick={() => openModal()}
          >
            <IconRestore style={{ fontSize: "30px" }} />
          </IconButton>
          <ListItemText primary="Reset User Settings" />
        </ListItem>
      </List>
      {modal && (
        <ModalBox
          Dialog={false}
          Content={
            <>
              {globalChanges?.length > 0 ? (
                <ListDesign
                  data={globalChanges}
                  resetSettings={resetSettings}
                  addCheckedBoxes={addCheckedBoxes}
                  addAllCheckedBoxes={addAllCheckedBoxes}
                  type={"All Application Settings"}
                />
              ) : null}
              {localChanges?.length > 0 ? (
                <ListDesign
                  data={localChanges}
                  addCheckedBoxes={addCheckedBoxes}
                  addAllCheckedBoxes={addAllCheckedBoxes}
                  type={process.env.REACT_APP_NAME_FRIENDLY}
                />
              ) : null}
              {props.tableReset?.length > 0 ? (
                <ListDesign
                  data={props.tableReset}
                  addCheckedBoxes={addCheckedBoxes}
                  addAllCheckedBoxes={addAllCheckedBoxes}
                  type={"Table Settings"}
                />
              ) : null}

              {getFormButtons()}
            </>
          }
          isFlexWidth={false}
        />
      )}
    </div>
  );
}

export default Content;
