import React from "react";
import "./personalInfoForm.style.css";

const PersonalInfoFormAvalanche = ({
  formData,
  onValueChangeHandler,
  countryList,
  onClickNext
}) => {
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    onClickNext();
  };
  return (
    <div className="form__inputs">
      <form className="form__inputs-box" onSubmit={onFormSubmitHandler}>
        <div className="form__inputs-header">
          <h3>Please provide your info</h3>
          <p>
            (<span className="required">*</span>) is required
          </p>
        </div>
        <div className="form__single-input">
          <label>
            First Name
            <span className="required">*</span>
          </label>
          <input
            type="text"
            name="FirstName"
            value={formData.FirstName}
            onChange={onValueChangeHandler}
            required
            placeholder="Enter first name"
          />
        </div>
        <div className="form__single-input">
          <label>
            Last Name
            <span className="required">*</span>
          </label>
          <input
            type="text"
            name="LastName"
            value={formData.LastName}
            onChange={onValueChangeHandler}
            required
            placeholder="Enter last name"
          />
        </div>
        <div className="form__single-input">
          <label>
            Country<span className="required">*</span>
          </label>
          <select
            name="userCountry"
            value={formData.userCountry}
            onChange={onValueChangeHandler}
            required
          >
            <option value="">Select country</option>
            {countryList.map((el) => (
              <option key={el} value={el}>
                {el}
              </option>
            ))}
          </select>
        </div>
        <div className="form__single-input">
          <label>
            Address
            <span className="required">*</span>
            <span className="label__helper-text">
              (Street address, p.o box, company name, c/o )
            </span>
          </label>
          <input
            type="text"
            name="userAddress"
            value={formData.userAddress}
            onChange={onValueChangeHandler}
            required
            placeholder="Enter address"
          />
        </div>
        <div className="form__single-input">
          <label>
            Address2{" "}
            <span className="label__helper-text">
              (Apt, suite, unit, building, floor etc)
            </span>
          </label>
          <input
            type="text"
            name="userAddress2"
            value={formData.userAddress2}
            onChange={onValueChangeHandler}
            placeholder="Address2"
          />
        </div>
        <div className="form__single-input">
          <label>
            City<span className="required">*</span>
          </label>
          <input
            type="text"
            name="userCity"
            value={formData.userCity}
            onChange={onValueChangeHandler}
            required
            placeholder="City"
          />
        </div>
        <div className="form__single-input">
          <label>
            State{" "}
            <span className="label__helper-text">
              {" "}
              (State, Province, Region, County etc )
            </span>
          </label>
          <input
            type="text"
            name="userState"
            value={formData.userState}
            onChange={onValueChangeHandler}
            placeholder="State / Province / Region / County"
          />
        </div>
        <div className="form__single-input">
          <label>
            Zip Code <span className="label__helper-text"> (Postal Code)</span>
          </label>
          <input
            type="text"
            name="userZip"
            value={formData.userZip}
            onChange={onValueChangeHandler}
            placeholder="Zip Code / Postal Code"
          />
        </div>
        <div className="form__single-input">
          <label>
            Phone<span className="required">*</span>{" "}
            <span className="label__helper-text">
              {" "}
              (Start with the country code)
            </span>{" "}
          </label>
          <div className="form__phone-input">
            <span className="form__plus-sign">+</span>
            <input
              type="number"
              name="userPhone"
              value={formData.userPhone}
              onChange={onValueChangeHandler}
              required
              placeholder="1312998009"
              pattern="[0-9]"
            />
          </div>
        </div>

        <button className="form__btn--next" type="submit">
          Next
        </button>
      </form>
    </div>
  );
};

export default PersonalInfoFormAvalanche;
