import React, { useEffect, useState } from "react";
import { countryList } from "../../collectBrokerInfo/statics/countryList";
import {
  b2cGetAccount,
  b2cLogout
} from "../../../components/sharedComponents/auth-wrapper";
import { enqueueSnackbar } from "notistack";
import APIUserSignAgreementForm from "../../../services/APIUserSignAgreementForm";
import APIPostAvalancheUserInfo from "../../../services/APIPostAvalancheUserInfo";
import APIAvalancheOfficeVerify from "../../../services/APIAvalancheOfficeVerify";
import { NSupportEmail } from "../../../components/sharedComponents";
import PersonalInfoFormAvalanche from "../personalnfoForm/PersonalInfoFormAvalanche";
import AddAccountFormAvalanche from "../addAccountForm/AddAccountFormAvalanche";
import AgreementFormAvalanche from "../agreementForm/AgreementFormAvalanche";
import StepsForm from "../stepsForm/StepsForm";
import SubmitFormAvalanche from "../submitForm/SubmitFormAvalanche";

import "./multiStepForm.style.css";

const defaultData = {
  userCountry: "",
  userAddress: "",
  userAddress2: "",
  userCity: "",
  userState: "",
  userZip: "",
  userPhone: "",
  userAccounts: []
};

const formStepsAdmis = [
  {
    order: 1,
    name: "Personal Info"
  },
  {
    order: 2,
    name: "Account Info"
  },

  {
    order: 3,
    name: "Sign Agreement"
  },
  {
    order: 4,
    name: "Submit"
  }
];

const formStepsAdmisi = [
  {
    order: 1,
    name: "Personal Info"
  },
  {
    order: 2,
    name: "Account Info"
  },
  {
    order: 3,
    name: "Submit"
  }
];

const MultiStepFormAvalanche = ({ firstName, lastName, apiClass }) => {
  const defaultAvalancheData = {
    ...defaultData,
    FirstName: firstName,
    LastName: lastName
  };

  const [formData, setFormData] = useState(defaultAvalancheData);
  const [currentStep, setCurrentStep] = useState(1);
  const [isAgreementSigned, setIsAgreementSigned] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [verifiedAccounts, setVerifiedAccounts] = useState([]);
  const [isErrorSubmitting, setIsErrorSubmitting] = useState(false);

  useEffect(() => {
    const apiUserSignAgreementForm = new APIUserSignAgreementForm(apiClass);
    const isUserSignedAgreementBefore = async () => {
      try {
        const response = await apiUserSignAgreementForm.getApi();
        setIsAgreementSigned(response);
      } catch (err) {
        console.log("error: ", err);
      }
    };
    isUserSignedAgreementBefore();
  }, [apiClass]);

  const userType = b2cGetAccount().idTokenClaims.extension_CustomerOfCountry;

  const onValueChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Send user info
  const sendUserInfo = async () => {
    const apiPostAvalancheUserInfo = new APIPostAvalancheUserInfo(apiClass);
    const request = {
      //PERSONAL INFO
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      userCountry: formData.userCountry,
      userAddress: formData.userAddress + " " + formData.userAddress2,
      userCity: formData.userCity,
      userState: formData.userState,
      userZip: formData.userZip,
      userPhone: formData.userPhone,
      userEmail: b2cGetAccount().username
    };
    try {
      let response = await apiPostAvalancheUserInfo.getApi(request);
      console.log("response", response);
    } catch (error) {
      if (error.response.status === 500) {
        enqueueSnackbar(error.message, { variant: "error" });
        setIsErrorSubmitting(true);
      } else {
        enqueueSnackbar(
          error.response.data,
          { variant: "warning" } ||
            `Something went wrong with submitting the form, please contact ${NSupportEmail()}`
        );
        setIsErrorSubmitting(true);
      }
    }
  };

  const onVerifyAccount = async (account) => {
    const apiVerifyAccount = new APIAvalancheOfficeVerify(apiClass);
    try {
      const response = await apiVerifyAccount.getApi(account);
      if (response === "Success.") {
        if (verifiedAccounts.includes(account)) {
          enqueueSnackbar("This account number was already verified.", {
            variant: "info"
          });
        } else {
          setVerifiedAccounts([...verifiedAccounts, account]);
        }
      }
    } catch (err) {
      enqueueSnackbar(err.response.data, { variant: "error" });
      console.log("error verifying account:", err);
    }
  };

  const onDeleteAccounts = (account) => {
    setVerifiedAccounts(verifiedAccounts.filter((el) => el !== account));
  };

  const onClickNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const onClickPrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else return;
  };

  const onClickSubmit = () => {
    sendUserInfo();
    setIsFormSubmitted(true);
  };

  // ----- ALL RENDER FUNCTIONS ----- //

  const renderForm = () => {
    return (
      <PersonalInfoFormAvalanche
        formData={formData}
        onValueChangeHandler={onValueChangeHandler}
        countryList={countryList}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
      />
    );
  };

  const renderAccount = () => {
    return (
      <AddAccountFormAvalanche
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        onDeleteAccounts={onDeleteAccounts}
        onVerifyAccount={onVerifyAccount}
        verifiedAccounts={verifiedAccounts}
      />
    );
  };

  const renderFormReview = () => {
    return (
      <SubmitFormAvalanche
        onClickSubmit={onClickSubmit}
        onClickPrev={onClickPrev}
        formData={formData}
        verifiedAccounts={verifiedAccounts}
      />
    );
  };

  const renderAgreement = () => {
    return (
      <AgreementFormAvalanche
        onClickSubmit={onClickSubmit}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
        userType={userType}
        accountList={formData.userAccounts}
        signerName={formData.FirstName + " " + formData.LastName}
        isAgreementSigned={isAgreementSigned}
        setIsAgreementSigned={setIsAgreementSigned}
        apiClass={apiClass}
      />
    );
  };

  const renderAdmisView = () => {
    switch (currentStep) {
      case 1:
        return renderForm();
      case 2:
        return renderAccount();
      case 3:
        return renderAgreement();
      case 4:
        return renderFormReview();
      default:
        break;
    }
  };

  const renderAdmisiView = () => {
    switch (currentStep) {
      case 1:
        return renderForm();
      case 2:
        return renderAccount();
      case 3:
        return renderFormReview();
      default:
        break;
    }
  };

  const renderEachView = () => {
    if (userType === "admis.com") {
      return renderAdmisView();
    } else return renderAdmisiView();
  };

  return (
    <div className="form__container">
      <h3 className="form__header">Collect Info</h3>
      <div className="form__steps-container">
        <StepsForm
          currentStep={currentStep}
          formSteps={
            userType === "admisi.com" ? formStepsAdmisi : formStepsAdmis
          }
          isFormSubmitted={isFormSubmitted}
        />
      </div>
      <div className="form__step-view">
        {!isFormSubmitted ? (
          renderEachView()
        ) : (
          <div className="success-box" style={{ height: "50vh" }}>
            <p className="success__message">
              Your form has been successfully submitted{" "}
            </p>
            {isErrorSubmitting ? (
              <button
                className="success__btn--home"
                onClick={() => {
                  setIsFormSubmitted(false);
                  setIsErrorSubmitting(false);
                  setCurrentStep(1);
                }}
              >
                Go back to first step
              </button>
            ) : null}
            <button
              className="success__btn--home"
              onClick={() => window.location.reload()}
            >
              Go to home page
            </button>
            <button
              className="success__btn--logout"
              onClick={() => b2cLogout()}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiStepFormAvalanche;
