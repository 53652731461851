import * as types from "./../../statics/actionConstants";
import { createReducer } from "@reduxjs/toolkit";

const User = createReducer([], {
  [types.USER_SETTINGS_LOCAL_SYMBOL]: (state, action) => {
    state.Settings.Local.Symbol.Code = action.Symbol.Code;
    state.Settings.Local.Symbol.Name = action.Symbol.Name;
  },
  [types.USER_SITES]: (state, action) => {
    state.Sites = action.Sites;
  },
  [types.USER_SETTINGS_LOCAL]: (state, action) => {
    let arr = action.Local;
    for (let item in arr) state.Settings.Local[item] = arr[item];
  },
  [types.USER_SETTINGS_GLOBAL]: (state, action) => {
    let arr = action.Global;
    for (let item in arr) state.Settings.Global[item] = arr[item];
  },
  [types.USER_BARCHART_CURRENT_ITEM]: (state, action) => {
    state.Barchart.CurrentItem = action.CurrentItem;
  },
  [types.USER_BARCHART_IS_FOCUSED]: (state) => {
    state.Barchart.IsFocused = !state.Barchart.IsFocused;
  }
});

export default User;
