import React, { useState, useEffect } from "react";
import IdleModal from "./IdleModal";

function IdleCheck(props) {
  const [isModalShown, setIsModalShown] = useState(false);

  //token expiration time counter. Initial time is 60 mins
  useEffect(() => {
    let intervalId = setInterval(function () {
      let sessionTimeLeft = props.b2cGetSessionTimeLeft();
      if (sessionTimeLeft > 10 && isModalShown) {
        return setIsModalShown(false);
      }
      if (sessionTimeLeft < 10 && sessionTimeLeft > 1) return showIdleModal();
      if (sessionTimeLeft <= 1) props.logoutSoft();
    }, 1000 * 60);
    return () => clearInterval(intervalId);
  });

  function showIdleModal() {
    setIsModalShown(true);
  }

  function acquireTokenPossibly() {
    return props.acquireTokenPossibly(true);
  }

  function hideIdleModal() {
    acquireTokenPossibly();
    setIsModalShown(false);
  }

  function forceLogoutUser() {
    props.logout();
  }

  return (
    <IdleModal
      showIdleModal={showIdleModal}
      hideIdleModal={hideIdleModal}
      isModalShown={isModalShown}
      logout={forceLogoutUser}
    />
  );
}

export default IdleCheck;
