import React from "react";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import "./idleModal.styles.css";

const IdleModal = ({ hideIdleModal, isModalShown, logout }) => {
  return (
    <div>
      <Modal
        aria-labelledby="Idle Modal"
        open={isModalShown}
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          margin: "-57px 0 0 -230px"
        }}
      >
        <div className="idle-modal-box">
          <span className="idle-modal-icon">&#9203;</span>
          <h6 className="text-center my-2">
            Your session time is about to expire...
          </h6>
          <div className="mt-3 text-center">
            <button
              className="button button--extend mx-2 w-25"
              onClick={hideIdleModal}
            >
              Extend time
            </button>
            <button
              className="button button--logout mx-2 w-25"
              onClick={logout}
            >
              log out
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

IdleModal.propTypes = {
  hideIdleModal: PropTypes.func.isRequired,
  isModalShown: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired
};

export default IdleModal;
