import React from "react";
import classNames from "classnames";
import "./stepsForm.style.css";

const StepsForm = ({ currentStep, formSteps, isFormSubmitted }) => {
  const decideClassNames = (order) => {
    if (currentStep > 1 && order === 1) {
      return true;
    } else if (currentStep > 2 && order === 2) {
      return true;
    } else if (currentStep > 3 && order === 3) {
      return true;
    } else if (formSteps.length === 3 && isFormSubmitted) {
      return true;
    } else if (currentStep === 4 && isFormSubmitted) {
      return true;
    } else return false;
  };

  const decideOrderView = (order) => {
    const checkMark = <span className="form__step__check-mark">&#x2714;</span>;
    if (currentStep > 1 && order === 1) {
      return checkMark;
    } else if (currentStep > 2 && order === 2) {
      return checkMark;
    } else if (currentStep > 3 && order === 3) {
      return checkMark;
    } else if (formSteps.length === 3 && isFormSubmitted) {
      return checkMark;
    } else if (currentStep === 4 && isFormSubmitted) {
      return checkMark;
    } else return order;
  };

  return (
    <>
      {formSteps.map(({ order, name }) => (
        <div
          className={classNames({
            "form__step-box--three": formSteps.length === 3,
            "form__step-box--four": formSteps.length === 4
          })}
          key={order}
        >
          <div
            className={classNames("form__step--num-box", {
              selected: currentStep === order && !isFormSubmitted,
              "step-done": decideClassNames(order)
            })}
          >
            <span className="form__step">{decideOrderView(order)}</span>
          </div>
          <p
            className={classNames("form__step--name", {
              "text-selected": currentStep === order,
              "text-done": decideClassNames(order)
            })}
          >
            {name}
          </p>
        </div>
      ))}
    </>
  );
};

export default StepsForm;
