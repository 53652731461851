import { AppIcons } from "../components/sharedComponents";
import BoxTheme from "../components/box-theme";
import WidgetFavoriteAccounts from "../components/widget-favorite-accounts";
import WidgetFavoriteResearches from "../components/widget-favorite-researches";
import WidgetFutureLeaders from "../components/widget-future-leaders";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
// import Drag from "./../img/drag.png";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import CancelIcon from "@mui/icons-material/Cancel";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { default as Alert, default as MuiAlert } from "@mui/material/Alert";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import ExpandIMG from "./../img/expand.png";
import {
  postUserBarchartCurrentItem,
  postUserBarchartIsFocused,
  postUserSettingsGlobal,
  postUserSettingsLocalSymbol,
  postUserSites
} from "./../redux/actions";
import BarChartList from "./BarChartList";
import {
  ukFavoriteSearchList,
  usFavoriteSearchList
} from "./favoriteSearchList";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  userSelect: "none",
  padding: "3px",
  margin: `0 0 5px 0`,
  background: isDragging ? "lightgreen" : "white",
  position: isDragging ? "initial" : "relative",
  height: "25px",
  left: "auto !important",
  top: "auto !important"
});

const getListStyle = (isDraggingOver) => ({
  background: !isDraggingOver ? "white" : "#ebebeb",
  padding: 8,
  width: 270,
  maxHeight: "60vh",
  overflow: "auto"
});

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AppBox: false,
      UserFavoriteAccounts: [],
      FutureLeaders: {},
      BarchartSymbols: [],
      UserFavoriteResearches: [],
      anchorElList: null,
      List: false,
      ListItems: null,
      LargeBarchart: false,
      displayedIcons: props.icons,
      InnerWidth: 0,
      CurrentBarchartItem: {
        Symbol: "ZC*1",
        Name: ""
      },
      FormUpdated: false,
      UserDragged: false,
      AppIconsHeight: 0,
      BarchartFullScreenHeight: window.innerHeight - 100
    };

    this.apiClass = props.apiClass;
    this.timeout = null;
  }

  componentDidMount() {
    let doit;
    window.onresize = () => {
      clearTimeout(doit);
      doit = setTimeout(() => {
        this.Resized();
      }, 100);
    };
    // Get Future Leaders
    this.GetFutureLeaders();
    // Get User's Research Favorites for the My Favorite Research
    this.GetFavoriteResearches();
    // Accounts
    this.PostAccountLVValues();
  }

  updateBarchartItem = async (Symbol, Name) => {
    let obj = { symbol: Symbol, symbolName: Name };
    this.updateBarchartUS(obj);

    this.setState({
      CurrentBarchartItem: {
        Symbol,
        Name
      },
      FormUpdated: true
    });
  };

  //desc: function shows hardcoded values on input focus. Different values for US and UK.
  showFavoriteOnFocus = () => {
    let userType = this.props.userType;
    let BarchartSymbols = [];
    if (userType === "admisi.com") BarchartSymbols = ukFavoriteSearchList;
    else BarchartSymbols = usFavoriteSearchList;
    this.setState({ BarchartSymbols });
  };

  GetBarchartSymbols = (symbol) => {
    return this.apiClass
      .GetBarchartSymbols(symbol)
      .then((res) => this.setState({ BarchartSymbols: res.results }));
  };

  GetFavoriteResearches = async () => {
    let UserAppSettings =
      await this.apiClass.GetUserAppSettings("nexuswebresearch");
    if (
      UserAppSettings.local &&
      UserAppSettings.local.customData &&
      UserAppSettings.local.customData.Favorites.length
    ) {
      this.setState({
        UserFavoriteResearches: UserAppSettings.local.customData.Favorites
      });
    }
  };

  GetFutureLeaders = async () => {
    let FutureLeaders = await this.apiClass.GetFutureLeaders();
    if (FutureLeaders)
      return this.setState({
        FutureLeaders: FutureLeaders.results.slice(0, 5)
      });
  };

  Resized() {
    this.setState({
      InnerWidth: window.innerWidth,
      BarchartFullScreenHeight: window.innerHeight - 100
    });
  }

  PostAccountLVValues = () => {
    if (this.props.getUserFavoriteAccounts?.length > 0)
      return this.apiClass
        .PostAccountLVValues(this.props.getUserFavoriteAccounts)
        .then((res) => {
          let UserAccount = {};
          let arr = [];
          res.length !== 0 &&
            res.map((item, index) =>
              arr.push(
                (UserAccount[index] = {
                  Account: item.officeAccount,
                  ChangeInLV: parseFloat(item.cur_LQVal - item.mlqval)
                })
              )
            );
          this.setState({ UserFavoriteAccounts: arr });
        })
        .catch((err) => console.log(err));
    else return "";
  };

  componentDidUpdate(prevProps) {
    //if icon sorting in parent changed update in state
    //displayedIcons: props.icons,
    // console.log(this.props.PendingUsersCount);
    // let icons = this.state.displayedIcons.filter((i) => i.appId !== "30");
    // this.testFunction(icons);
    if (
      !this.state.UserDragged &&
      this.state.displayedIcons !== this.props.icons
    )
      this.setState({ displayedIcons: this.props.icons });
    // BarChart
    if (
      this.props.getUserLocalSymbol.Code &&
      this.state.CurrentBarchartItem.Symbol !==
        this.props.getUserLocalSymbol.Code
    ) {
      this.setState({
        CurrentBarchartItem: {
          Symbol: this.props.getUserLocalSymbol.Code,
          Name: this.props.getUserLocalSymbol.Name
        }
      });
    } else if (
      !this.props.getUserLocalSymbol.Code &&
      !this.state.FormUpdated &&
      this.state.FutureLeaders[0] &&
      this.state.FutureLeaders[0].symbol !==
        this.state.CurrentBarchartItem.Symbol &&
      this.state.FutureLeaders[0] &&
      this.state.FutureLeaders[0].symbol !== null
    ) {
      this.setState({
        CurrentBarchartItem: {
          Symbol: this.state.FutureLeaders[0].symbol,
          Name: this.state.FutureLeaders[0].symbolName
        }
      });
    }
    if (
      this.props.getUserFavoriteAccounts.length > 0 &&
      prevProps.getUserFavoriteAccounts !==
        this.props.getUserFavoriteAccounts &&
      this.props.getUserFavoriteAccounts !== []
    ) {
      this.PostAccountLVValues();
    }

    //icons
    if (
      this.state.displayedIcons.length > 0 &&
      this.state.AppIconsHeight !==
        document.getElementById("AppIconsHeight").offsetHeight
    )
      this.setState({
        AppIconsHeight: document.getElementById("AppIconsHeight").offsetHeight
      });
  }

  updateBarchartUS(item, reset) {
    if (reset) {
      this.props.postUserBarchartCurrentItem("");
      if (this.props.getUserBarchartIsFocused) {
        this.props.postUserBarchartIsFocused();
      }
    }
    let ExchangeSymbol = {
      Code: item.symbol,
      Name: item.symbolName
    };
    this.props.postUserSettingsLocalSymbol(ExchangeSymbol);
    let CurrentUserLocalSettings = {
      ...this.props.getUserLocalSettings,
      Symbol: { Code: ExchangeSymbol.Code, Name: ExchangeSymbol.Name }
    };
    // Update User Settings
    this.PostUserSettings(process.env.REACT_APP_ID, CurrentUserLocalSettings);
  }
  PostUserSettings = async (app, customData) => {
    return this.apiClass.PostUserSettings(app, customData);
  };
  displayAllApps() {
    this.setState((prevState) => ({ AppBox: !prevState.AppBox }));
  }
  userEnterSymbol(userTypedSymbol) {
    this.GetBarchartSymbols(userTypedSymbol);
    let symbol = this.state.BarchartSymbols.filter((item) =>
      item.symbol.includes(userTypedSymbol)
    );
    if (symbol[0])
      this.props.postUserSettingsLocalSymbol({
        Code: symbol[0].symbol,
        Name: symbol[0].symbolName
      });
    else
      this.props.postUserSettingsLocalSymbol({
        Code: userTypedSymbol
      });
  }

  popperDisplay = (event, popperName) => {
    const { currentTarget } = event;
    const anchorName = "anchorEl" + popperName;
    this.setState((state) => ({
      [anchorName]: currentTarget,
      [popperName]: !state.name
    }));
  };

  handleClickAway = (name) => {
    this.setState({
      [name]: false
    });
  };
  onDragEnd = (result) => {
    if (!this.state.UserDragged) this.setState({ UserDragged: true });
    if (!result.destination) {
      return;
    }
    const items = reorder(
      this.state.displayedIcons.filter((i) => i.appUrl && i.icon),
      result.source.index,
      result.destination.index
    );
    this.setState({ displayedIcons: items });
    this.saveToGlobalSettings(items);
  };

  saveToGlobalSettings(items) {
    let resultSet = items.map((c) => c.appId);
    let customData = this.props.getUserSettingsGlobal;
    let currentTilesSettings =
      customData &&
      customData.LayoutSettings &&
      customData.LayoutSettings.AccountSnapshot
        ? customData.LayoutSettings.AccountSnapshot
        : {};
    let newLayoutSettings = {
      AccountSnapshot: currentTilesSettings,
      Landing: {
        IconsLayout: resultSet
      }
    };
    let customDataFull = {
      ...customData,
      LayoutSettings: newLayoutSettings
    };
    // Update User Settings
    this.PostUserSettings("global", customDataFull);
  }

  handleModal = () => {
    this.setState({ LargeBarchart: !this.state.LargeBarchart });
  };

  getExpandButton = () => {
    let style = { fontSize: "35px", color: "#707070" };
    if (this.state.AppBox) return <ExpandLessIcon style={style} />;
    else return <ExpandMoreIcon style={style} />;
  };

  // testFunction(icons) {
  //   return (
  //     // <Suspense
  //     //   fallback={
  //     //     <AppIcons
  //     //       iconSizes={[6, 4, 3, 2]} // xs, sm, md, lg
  //     //       sites={icons}
  //     //       UnreadMessageCount={0}
  //     //       pendingUsersCount={0}
  //     //       fontSize="1rem"
  //     //       iconImageSize="80px"
  //     //     />
  //     //     // <div>hello</div>
  //     //   }
  //     // >
  //     <AppIcons
  //       iconSizes={[6, 4, 3, 2]} // xs, sm, md, lg
  //       sites={icons}
  //       UnreadMessageCount={this.props.UnreadMessageCount}
  //       pendingUsersCount={this.props.pendingUsersCount}
  //       fontSize="1rem"
  //       iconImageSize="80px"
  //     />
  //     // </Suspense>
  //   );
  // }
  render() {
    let userType = this.props.userType;
    let companyName = userType
      ? userType?.split(".")[0]?.toUpperCase()
      : "ADMIS";
    let barchartLink =
      "https://admisclientservices.websol.barchart.com/?module=futuresInteractiveChart&symbol=" +
      this.state.CurrentBarchartItem.Symbol +
      "&hideDisclaimer=1";
    let icons = this.state.displayedIcons.filter((i) => i.appUrl && i.icon);

    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Popper
              className="List"
              style={{ zIndex: 10 }}
              open={this.state.List}
              placement="bottom-end"
              anchorEl={this.state.anchorElList}
              transition
            >
              {({ TransitionProps }) => (
                <ClickAwayListener
                  onClickAway={(e) => this.handleClickAway("List")}
                >
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <div>
                        <Alert severity="info">
                          <p style={{ fontSize: "13px" }}>
                            Drag and drop to change the order
                          </p>
                        </Alert>
                      </div>
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {icons.map((item, index) => (
                                <Draggable
                                  key={item.appName}
                                  draggableId={item.appName}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <span
                                        style={{
                                          fontSize: "16px"
                                        }}
                                      >
                                        <DragIndicatorIcon
                                          style={{
                                            float: "left",
                                            fontSize: "18px",
                                            color: "gray",
                                            marginTop: "3px",
                                            marginRight: "5px"
                                          }}
                                        />
                                        {item.title}
                                      </span>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
            <BoxTheme
              title="Favorite Apps"
              controlAction={(e) => this.popperDisplay(e, "List")}
              component={
                <>
                  {this.props.isDemoUser && (
                    <MuiAlert
                      severity="success"
                      elevation={0}
                      variant="standard"
                    >
                      {`“You have successfully registered for Nexus. ${companyName} is working to add account permissions and finalize your Nexus setup. Once completed, ${companyName} Support Desk will send you an email confirmation. Until you receive the email, please feel free to access the research information available on Nexus”.`}
                    </MuiAlert>
                  )}
                  {icons.length > 0 ? (
                    <div
                      style={{
                        height: this.state.AppBox === false ? "130px" : "auto",
                        overflow: "hidden"
                      }}
                    >
                      <div id="AppIconsHeight">
                        <AppIcons
                          iconSizes={[6, 4, 3, 2]} // xs, sm, md, lg
                          sites={icons}
                          UnreadMessageCount={this.props.UnreadMessageCount}
                          pendingUsersCount={this.props.PendingUsersCount}
                          fontSize="1rem"
                          iconImageSize="80px"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {(icons.length > 0) & (this.state.AppIconsHeight > 134) ? (
                    <div
                      style={{
                        width: " 70px",
                        position: "absolute",
                        bottom: "-30px",
                        left: " 50%",
                        marginLeft: "-35px",
                        zIndex: "0"
                      }}
                      xs={12}
                    >
                      <Fab
                        color="primary"
                        aria-label="expand"
                        className="AppBoxExtend"
                        onClick={() => this.displayAllApps()}
                      >
                        {this.getExpandButton()}
                      </Fab>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              }
            />
          </Grid>
          <Grid item xs={12} style={{ margin: "50px 0 20px 0" }}>
            <BoxTheme
              alwaysDisplay
              title={this.state.CurrentBarchartItem.Name}
              controlNearBoxStatus={this.state.SymbolAutocompleteBox}
              controlNearBox={
                <BarChartList
                  data={this.state.BarchartSymbols}
                  func={(d) => this.GetBarchartSymbols(d)}
                  update={(s, n) => this.updateBarchartItem(s, n)}
                  showFavoriteOnFocus={this.showFavoriteOnFocus}
                />
              }
              component={
                <>
                  <div style={{ position: "relative" }}>
                    <iframe
                      src={barchartLink}
                      frameBorder="0"
                      scrolling="no"
                      title="BarChart"
                      width="100%"
                      height="340px"
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        right: "4px",
                        bottom: "4px"
                      }}
                      onClick={() => this.handleModal()}
                    >
                      <img src={ExpandIMG} alt="Expand" width="32" />
                    </IconButton>
                    <Modal
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      open={this.state.LargeBarchart}
                      onClose={() => this.handleModal()}
                    >
                      <div
                        style={{
                          width: "95%",
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)"
                        }}
                      >
                        <div id="BarchartClose">
                          <IconButton onClick={() => this.handleModal()}>
                            <CancelIcon />
                          </IconButton>
                        </div>
                        <iframe
                          src={`${barchartLink}&chartHolderHeight=${this.state.BarchartFullScreenHeight}px`}
                          frameBorder="0"
                          scrolling="no"
                          title="BarChart"
                          width="100%"
                          height={`${
                            this.state.BarchartFullScreenHeight + 50
                          }px`}
                        />
                      </div>
                    </Modal>
                  </div>
                </>
              }
            />
          </Grid>
          <Grid container id="Widgets" spacing={4}>
            {Object.entries(this.state.FutureLeaders).length !== 0 && (
              <Grid item xs>
                <BoxTheme
                  title="Leaders - Upside"
                  component={
                    <WidgetFutureLeaders
                      style={{ height: "170px" }}
                      items={this.state.FutureLeaders}
                      action={(exchange) =>
                        this.updateBarchartUS(exchange, true)
                      }
                    />
                  }
                />
              </Grid>
            )}
            {Object.entries(this.state.UserFavoriteAccounts).length !== 0 && (
              <Grid item xs>
                <WidgetFavoriteAccounts
                  items={this.state.UserFavoriteAccounts}
                />
              </Grid>
            )}
            {this.state.UserFavoriteResearches.length > 0 && (
              <Grid item xs>
                <WidgetFavoriteResearches
                  items={this.state.UserFavoriteResearches}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getUserFavoriteAccounts: state.User.Settings.Global.FavoriteAccounts,
    getUserLocalSymbolCode: state.User.Settings.Local.Symbol.Code,
    getUserLocalSymbol: state.User.Settings.Local.Symbol,
    getUserLocalSettings: state.User.Settings.Local,
    getUserSettingsGlobal: state.User.Settings.Global,
    getUserSites: state.User.Sites,
    getUserSettings: state.User.Settings,
    getUserBarchartIsFocused: state.User.Barchart.IsFocused
  };
};

const mapDispatchToProps = {
  postUserSettingsLocalSymbol,
  postUserSites,
  postUserSettingsGlobal,
  postUserBarchartCurrentItem,
  postUserBarchartIsFocused
};
export default connect(mapStateToProps, mapDispatchToProps)(Content);
