import Dialog from "./dialog";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import React from "react";

function getModalStyle(isFlexWidth) {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: isFlexWidth ? "90%" : "auto",
    minWidth: isFlexWidth ? "700px" : "0px",
    maxWidth: isFlexWidth ? "100%" : "1200px",
    height: "auto",
    maxHeight: "90vh",
    overflow: "auto"
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "10px"
  }
}));

export default function ModalBox(props) {
  const classes = useStyles();
  // const [height, setHeight] = React.useState(window.innerHeight - 100);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle(props.isFlexWidth));
  const [open, setOpen] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const handleClose = () => {
    // show dialog, if user clicks yes then close
    setDialog(true);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {props.Content}
    </div>
  );

  return (
    <div>
      {props.Dialog && dialog === true && (
        <Dialog
          title="Close form and lose changes?"
          content=""
          noAction={() => setDialog(false)}
          yesAction={() => {
            setOpen(false);
            setDialog(false);
          }}
        />
      )}

      <Modal
        open={open}
        style={{ zIndex: 99999 }}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
