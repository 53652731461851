import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./addAccountForm.style.css";

const typeOptions = {
  officeAccount: "officeAccount",
  office: "office",
  accounName: "accountName"
};

const AddAccountForm = ({
  onClickNext,
  onClickPrev,
  onAddAccounts,
  addedAccounts,
  onDeleteAccounts
}) => {
  const [currentItem, setCurrentItem] = useState("");
  const [selectedType, setSelectedType] = useState(typeOptions.officeAccount);
  const [warningMessage, setWarningMessage] = useState("");

  const onChangeHandler = (e) => {
    setWarningMessage("");
    setCurrentItem(e.target.value);
  };

  const onAddClickHandler = () => {
    if (currentItem.length === 0) {
      return;
    }
    if (selectedType === "office" && currentItem.length !== 3) {
      return setWarningMessage("Please make sure length is 3 digits");
    } else if (selectedType === "officeAccount" && currentItem.length !== 8) {
      return setWarningMessage("Please make sure length is 8 digits");
    }
    const addedAccount = {
      value: currentItem,
      type: selectedType,
      id: uuidv4()
    };
    onAddAccounts(addedAccount);
    // setAddedItems([
    //   ...addedItems,
    //   { value: currentItem, type: selectedType, id: uuidv4() },
    // ]);
    setCurrentItem("");
  };

  const onTypeChangeHandler = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="accounts-box">
      <div className="accounts-top">
        <p>
          To prevent delays during user verification process fill out the next
          text fields:{" "}
        </p>
        <div className="accounts__inputs">
          <div className="accounts__input-box select">
            <label>Choose type:</label>
            <select name="typeName" onChange={onTypeChangeHandler}>
              <option value={typeOptions.officeAccount}>
                office and account (8 digits)
              </option>
              <option value={typeOptions.office}>office (3 digits)</option>
              <option value={typeOptions.accounName}>Account Name</option>
            </select>
          </div>
          <div className="accounts__input-box input">
            <label>Provide Info: </label>
            <input
              name="accounts"
              value={currentItem}
              onChange={onChangeHandler}
            />
          </div>
          <button className="accounts__add-btn" onClick={onAddClickHandler}>
            +
          </button>
        </div>
        <span className="accounts__warning">{warningMessage}</span>
        <p>Already added information:</p>
        <div className="accounts__tags-box">
          {addedAccounts.map((el, index) => (
            <div key={index} className="accounts__tag">
              <span className="accounts__tag--value ">
                <span className="accounts__tag--type">{el.type}:</span>
                {el.value}
              </span>
              <button onClick={() => onDeleteAccounts(el.id)}>&otimes;</button>
            </div>
          ))}
        </div>
      </div>
      <div className="accounts__btn-group">
        <button className="accounts__btn--prev" onClick={onClickPrev}>
          Prev
        </button>
        <button className="accounts__btn--next" onClick={onClickNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default AddAccountForm;
