import React from "react";
import "./submitForm.style.css";

const SubmitForm = ({ formData, onClickPrev, onClickSubmit }) => {
  return (
    <div className="submit-box">
      <h1>Please review your information:</h1>
      <div className="submit-label-box">
        <span className="submit-label">Country: </span>{" "}
        <p>{formData.userCountry}</p>
      </div>
      <div className="submit-label-box">
        <span className="submit-label">Address: </span>
        <p>{formData.userAddress}</p>
      </div>
      <div className="submit-label-box">
        <span className="submit-label">Address 2: </span>
        <p>{formData.userAddress2}</p>
      </div>
      <div className="submit-label-box">
        <span className="submit-label">City:</span>
        <p>{formData.userCity}</p>
      </div>
      <div className="submit-label-box">
        <span className="submit-label">State: </span>
        <p> {formData.userState?.toUpperCase()}</p>
      </div>
      <div className="submit-label-box">
        <span className="submit-label">Zip:</span>
        <p>{formData.userZip}</p>
      </div>
      <div className="submit-label-box">
        <span className="submit-label">Phone:</span>
        <p>{formData.userPhone}</p>
      </div>
      <div
        className="submit-label-box"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <span className="submit-label">Added Accounts:</span>
        <div>
          {formData.userAccounts.map((el) => (
            <span key={el.id}>{el.value}, </span>
          ))}
        </div>
      </div>

      <div className="submit__btn-group">
        <button className="submit__btn--prev" onClick={onClickPrev}>
          Prev
        </button>
        <button className="submit__btn--submit" onClick={onClickSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default SubmitForm;
