import { NSupportEmail } from "../../helper-functions/NSupportEmail";
// API Caller
export const APICall = {
  Error:
    "Something went wrong, if you continue to get this message please contact " +
    `Support Desk at ${NSupportEmail()} with below information`
};

// Statements
export const Statements = {
  NoData: "For this date, no information can be found for this account."
};
