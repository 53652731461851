export function GetLastBusinessDate() {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  let YesterdayInNumber = d.getDay();
  if (YesterdayInNumber === 0) {
    d.setDate(d.getDate() - 2);
  } else if (YesterdayInNumber === 6) {
    d.setDate(d.getDate() - 1);
  }
  return d;
}
export function GetLastBusinessDateDashedYMD() {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  let YesterdayInNumber = d.getDay();
  if (YesterdayInNumber === 0) {
    d.setDate(d.getDate() - 2);
  } else if (YesterdayInNumber === 6) {
    d.setDate(d.getDate() - 1);
  }
  let month = d.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  let day = d.getDate();
  day = day < 10 ? `0${day}` : day;
  let year = d.getFullYear();
  let full = `${year}-${month}-${day}`;
  return full;
}

export function GetTodaysDateZerosMDY() {
  let d = new Date();
  let YesterdayInNumber = d.getDay();
  if (YesterdayInNumber === 0) {
    d.setDate(d.getDate() - 2);
  } else if (YesterdayInNumber === 6) {
    d.setDate(d.getDate() - 1);
  }
  let month = d.getMonth() + 1;
  let day = d.getDate();
  let year = d.getFullYear();
  let full = `${month}/${day}/${year}`;
  return full.replace(/\b(\d{1})\b/g, "0$1");
}

export function GetLastBusinessDateZerosMDY() {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  let YesterdayInNumber = d.getDay();
  if (YesterdayInNumber === 0) {
    d.setDate(d.getDate() - 2);
  } else if (YesterdayInNumber === 6) {
    d.setDate(d.getDate() - 1);
  }
  let month = d.getMonth() + 1;
  let day = d.getDate();
  let year = d.getFullYear();
  let full = `${month}/${day}/${year}`;
  return full.replace(/\b(\d{1})\b/g, "0$1");
}

export function PrettyPrice(num, decimal) {
  if (!num) return "";
  if (num === 0) return 0;
  // fix decimals
  if (decimal === true) decimal = 2;
  else if (decimal === false) decimal = 0;
  num = parseFloat(num);
  num = num.toFixed(decimal).toString();
  // get the position
  let pos = decimal === 0 ? num.length : num.indexOf(".");
  // apply comma
  let UntilDot = num.substring(0, pos).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // join
  let FullNum =
    decimal === 0
      ? UntilDot
      : UntilDot + num.substring(num.indexOf("."), num.length);
  return FullNum;
}

export function GetLastMonthEndLastBusinessDate() {
  let d = new Date();
  d.setDate(1);
  d.setHours(-23);
  while (d.getDay() === 6 || d.getDay() === 0) d.setDate(d.getDate() - 1);
  return d;
}
export function GetEndOfMonthBusinessDateList(begin, end) {
  let endDate = end === "now" ? new Date() : new Date(end);
  let arr = [];
  let month = parseInt(endDate.getMonth() - 1);
  let year = parseInt(endDate.getFullYear());
  let yearDestination = parseInt(begin);
  while (year >= yearDestination) {
    while (month >= 0) {
      let d = new Date(year, month + 1, 0);
      while (d.getDay() === 6 || d.getDay() === 0) d.setDate(d.getDate() - 1);
      let mO =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      arr.push(mO + "/" + d.getDate() + "/" + d.getFullYear());
      month -= 1;
    }
    year -= 1;
    month = 11;
  }
  return arr;
}

export function FormatDateForMUICalendar(date) {
  return `${date.substring(6, 10)}-${date.substring(0, 2)}-${date.substring(
    3,
    5
  )}`;
}
