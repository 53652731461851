export const ukFavoriteSearchList = [
  {
    exchange: "Forex - FOREX",
    name: "Euro Fx/U.S. Dollar",
    symbol: "^EURUSD",
    type: "Forex"
  },
  {
    exchange: "Forex - FOREX",
    name: "British Pound/U.S. Dollar",
    symbol: "^GBPUSD",
    type: "Forex"
  },
  {
    exchange: "Forex - FOREX",
    name: "Euro Fx/British Pound",
    symbol: "^EURGBP",
    type: "Forex"
  },
  {
    exchange: "Forex - FOREX",
    name: "U.S. Dollar/Swiss Franc",
    symbol: "^USDCHF",
    type: "Forex"
  },
  {
    exchange: "Forex - FOREX",
    name: "U.S. Dollar/Japanese Yen",
    symbol: "^USDJPY",
    type: "Forex"
  }
];

//desc: Future dates changes every quarter (3 months). This function helps to change month and date automatically.
const getCorrectMonthAndYear = (futureType) => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (futureType === "Soybean") {
    //soybeans doesnt go with quarters, they go with crop season, so 6 months.
    if ((mm === 1 || mm === 3 || mm === 5) && dd > 0) {
      //have new future contracts
      return `${futureType} ${showMonth(mm + 2)} ${yyyy}`;
    } else if (mm === 2 || mm === 4 || mm === 6 || mm === 10) {
      return `${futureType} ${showMonth(mm + 1)} ${yyyy}`;
    } else if (mm === 7 && dd > 0) {
      return `${futureType} ${showMonth(mm + 1)} ${yyyy}`;
    } else if (mm === 8 && dd > 0) {
      return `${futureType} ${showMonth(mm + 3)} ${yyyy}`;
    } else if (mm === 11 && dd > 0) {
      return `${futureType} ${showMonth(mm + 2)} ${yyyy + 1}`;
    } else if (mm === 9) {
      return `${futureType} ${showMonth(mm + 2)} ${yyyy}`;
    } else if (mm === 12) {
      return `${futureType} ${showMonth(1)} ${yyyy + 1}`;
    }
  } else {
    //for corn, wheat and s&p 500 e-mini
    if ((mm === 3 || mm === 6 || mm === 9) && dd > 0) {
      return `${futureType} ${showMonth(mm + 3)} ${yyyy}`; // if its beginning of the quarter month, will show next quarter month
    } else if (mm === 12 && dd > 0) {
      return `${futureType} ${showMonth(3)} ${yyyy + 1}`; //if its beginning of December will show next year March
    } else if (mm === 4 || mm === 7 || mm === 10) {
      return `${futureType} ${showMonth(mm + 2)} ${yyyy}`;
    } else if (mm === 5 || mm === 8 || mm === 11) {
      return `${futureType} ${showMonth(mm + 1)} ${yyyy}`;
    }
  }
};

//for every quarter the symbol for the future changes, except Soybeans, it changes six times a year
//H-march, K-may, M- june, N-July, Q-August, X-november, U-September, Z-December
const getCorrectFutureSymbol = (futureType) => {
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear() + "";
  const yy = yyyy.charAt(2) + yyyy.charAt(3);
  if (futureType === "Soybean") {
    if (mm === 1 || mm === 2) {
      return `H${yy}`;
    } else if (mm === 3 || mm === 4) {
      return `K${yy}`;
    } else if (mm === 5 || mm === 6) {
      return `N${yy}`;
    } else if (mm === 7 || (mm === 8 && dd < 15)) {
      return `Q${yy}`;
    } else if ((mm === 8 && dd > 15) || mm === 9 || mm === 10) {
      return `X${yy}`;
    } else if (mm === 11 || mm === 12) {
      return `F${yy + 1}`;
    }
  } else {
    if (mm === 1 || mm === 2) {
      return `H${yy}`;
    } else if (mm === 3 || mm === 4 || mm === 5) {
      return `M${yy}`;
    } else if (mm === 6 || mm === 7 || mm === 8) {
      return `U${yy}`;
    } else if (mm === 9 || mm === 10 || mm === 11) {
      return `Z${yy}`;
    } else if (mm === 12) {
      return `H${yy + 1}`;
    }
  }
};

//HELPER FUNCTION TO RETURN NAME OF THE MONTH
const showMonth = (num) => {
  let monthName = "";
  //I have the months needed only
  switch (num) {
    case 1:
      monthName = "January";
      break;
    case 3:
      monthName = "March";
      break;
    case 5:
      monthName = "May";
      break;
    case 6:
      monthName = "June";
      break;
    case 7:
      monthName = "July";
      break;
    case 8:
      monthName = "August";
      break;
    case 9:
      monthName = "September";
      break;
    case 11:
      monthName = "November";
      break;
    case 12:
      monthName = "December";
      break;
    default:
      break;
  }
  return monthName;
};

export const usFavoriteSearchList = [
  {
    exchange: "Futures - CBOT",
    name: getCorrectMonthAndYear("Corn"),
    symbol: `ZC${getCorrectFutureSymbol("Corn")}`,
    type: "Futures"
  },
  {
    exchange: "Futures - CBOT",
    name: getCorrectMonthAndYear("Wheat"),
    symbol: `ZW${getCorrectFutureSymbol("Wheat")}`,
    type: "Futures"
  },
  {
    exchange: "Futures - CBOT",
    name: getCorrectMonthAndYear("Soybean"),
    symbol: `ZS${getCorrectFutureSymbol("Soybean")}`,
    type: "Futures"
  },
  {
    exchange: "Futures - GBLX",
    name: getCorrectMonthAndYear("S&P 500 E-Mini"),
    symbol: `ES${getCorrectFutureSymbol("S&P 500 E-Mini")}`,
    type: "Futures"
  }
];
