import IconButton from "@mui/material/IconButton";
import React, { Component } from "react";
import { connect } from "react-redux";
import { components } from "react-select";
import Creatable from "react-select/creatable";
import {
  postUserBarchartCurrentItem,
  postUserBarchartIsFocused
} from "./../redux/actions";

class BarChartList extends Component {
  SingleValue = (props) => {
    return (
      <input
        type="text"
        placeholder="Search for a Symbol or Product."
        id="Creatable-SingleValue-Input"
        style={{
          outline: "0",
          backgroundColor: "transparent",
          border: "0",
          width: "500px",
          color: "#000",
          fontFamily: "'Roboto', sans-serif",
          fontSize: "18px",
          fontWeight: "bold",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "38px",
          letterSpacing: "-0.36px",
          marginBottom: "5px"
        }}
        value={this.props.getUserBarchartCurrentItem}
        onFocus={() => {
          this.props.postUserBarchartIsFocused();
          this.props.showFavoriteOnFocus();
        }}
        autoComplete="off"
        onKeyDown={(e) => {
          if (e.target.value) {
            e.stopPropagation();
            !this.props.getUserBarchartIsFocused &&
              this.props.postUserBarchartIsFocused();
          }
        }}
        onChange={(e) => {
          this.props.postUserBarchartCurrentItem(e.target.value);
          e.target.value.length >= 2 && this.props.func(e.target.value);
        }}
      />
    );
  };
  Input = (props) => {
    return <div></div>;
  };
  Option = (props) => {
    return (
      <div className="options">
        <components.Option {...props} />
      </div>
    );
  };
  formatOptionLabel = ({ symbol, name, exchange }) => {
    return (
      <>
        <IconButton
          className="label"
          onClick={() => {
            this.props.postUserBarchartIsFocused();
            this.props.update(symbol, name);
          }}
        >
          <span className="bctSymbol">{symbol} </span>
          <span className="bctName">{name}</span>
          <span className="bctExch">{exchange}</span>
        </IconButton>
      </>
    );
  };

  DropdownIndicator = (props) => {
    return "";
    // return (
    //   <div
    //     onBlur={() => {
    //       this.setState({ Focused: false });
    //     }}
    //     style={{ outline: "0" }}
    //     id="DropdownIndicator"
    //     tabIndex="12"
    //     onClick={() => {
    //       !this.props.getUserBarchartIsFocused &&
    //         this.props.postUserBarchartIsFocused();
    //       document.getElementById("DropdownIndicator").focus();
    //     }}
    //   >
    //     <components.DropdownIndicator {...props}>
    //       <ArrowDropDownIcon
    //         style={{ borderRadius: "1px", color: "#50606e" }}
    //       />
    //     </components.DropdownIndicator>
    //   </div>
    // );
  };
  render() {
    const customStyles = {
      indicatorSeparator: (base) => ({
        // remove separator
        ...base,
        display: "none"
      }),
      input: () => ({
        //border: "none",
        color: "red"
      }),
      control: (provided, state) => {
        return { ...provided, border: "none", height: "35px" };
      }
    };
    return (
      <Creatable
        id="Select"
        styles={customStyles}
        menuIsOpen={this.props.getUserBarchartIsFocused}
        style={{
          width: "100px",
          border: "none"
        }}
        formatOptionLabel={this.formatOptionLabel}
        isSearchable={true}
        formatCreateLabel={(userInput) => `${userInput}`}
        components={{
          SingleValue: this.SingleValue,
          Input: this.Input,
          DropdownIndicator: this.DropdownIndicator,
          Option: this.Option
        }}
        options={this.props.data}
        value={{
          label: this.props.getUserBarchartCurrentItem,
          value: this.props.getUserBarchartCurrentItem
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getUserBarchartIsFocused: state.User.Barchart.IsFocused,
    getUserBarchartCurrentItem: state.User.Barchart.CurrentItem
  };
};
const mapDispatchToProps = {
  postUserBarchartIsFocused,
  postUserBarchartCurrentItem
};
export default connect(mapStateToProps, mapDispatchToProps)(BarChartList);
