import React from "react";
import Controls from "./controls.png";
import "./index.css";
import IconButton from "@mui/material/IconButton";
import IconNext from "@mui/icons-material/NavigateNext";
import IconPrev from "@mui/icons-material/NavigateBefore";

function getControls(title, action) {
  return (
    <>
      <IconButton
        style={{ position: "absolute", right: "5px", top: "5px" }}
        aria-label={title + " Controls"}
        onClick={action}
      >
        <img src={Controls} alt={title + " Controls"} />
      </IconButton>
    </>
  );
}

export default function index(props) {
  return (
    <div className="BoxTheme">
      <div
        style={{
          margin: "10px 10px 5px 20px",
          height: "30px"
        }}
      >
        <h1
          style={{
            display: "inline-block"
          }}
        >
          {props.title}
        </h1>
        {props.controlAction && getControls(props.title, props.controlAction)}
        {props.Paging && (
          <div id="WidgetPaging">
            {props.PagingPrev && (
              <IconButton
                id="WidgetPagingPrev"
                onClick={() => props.Paging("Prev")}
              >
                <IconPrev />
              </IconButton>
            )}

            {props.PagingNext && (
              <IconButton
                id="WidgetPagingNext"
                onClick={() => props.Paging("Next")}
              >
                <IconNext />
              </IconButton>
            )}
          </div>
        )}
        <div
          style={{
            display: "inline-block",
            visibility:
              props.controlNearBoxStatus || props.alwaysDisplay
                ? "visible"
                : "hidden",
            position: "absolute",
            right: props.alwaysDisplay ? 0 : "60px",
            top: 0
          }}
        >
          {props.controlNearBox}
        </div>
      </div>
      <hr
        style={{
          margin: "0 0 6px 0",
          borderTop: "1px solid #DEE1E8",
          borderStyle: "ridge"
        }}
      />
      {props.component}
    </div>
  );
}
