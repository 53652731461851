import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Link from "@mui/material/Link";

export default function WidgetFutureLeaders(props) {
  return (
    <div className="WidgetBox">
      <ul className="WidgetList">
        {props.items.map((item, index) => (
          <li className="WidgetLI" key={index}>
            <Link
              href="#"
              onClick={() => props.action(item)}
              style={{ display: "inline-block", verticalAlign: "middle" }}
            >
              {item.symbolName}
            </Link>
            <div className="WidgetPercentage">
              {item.pricePercentChange > 0 ? (
                <>
                  <div className="Positive">
                    {item.pricePercentChange}%
                    <ArrowUpwardIcon className="WidgetArrow" />
                  </div>
                </>
              ) : (
                <>
                  <div className="Negative">
                    {item.pricePercentChange}%
                    <ArrowDownwardIcon className="WidgetArrow" />
                  </div>
                </>
              )}
              {item.pricePercentChange === 0 && item.pricePercentChange}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
